

// import { Card, CircularProgress, Stack, Typography, Grid, useMediaQuery , Button} from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { getDailyReport, sendEmailForDetectedFaces } from '../../API/Admin';
// import Icon from 'react-icons-kit';
// import {cloudUpload} from 'react-icons-kit/fa/cloudUpload'
// import {cameraRetro} from 'react-icons-kit/fa/cameraRetro'
// import {user} from 'react-icons-kit/fa/user'
// import {book} from 'react-icons-kit/fa/book'
// import { useTheme } from "@mui/material";

// export default function DailyReport() {
//     const [dailyData, setDailyData] = useState();

    
//      const theme = useTheme ();
//      const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

     

//     const mediaScreen=useMediaQuery(theme.breakpoints.up("md"))
//     const dailyReports = async () => {
//         try {
//             const response = await getDailyReport();
//             //console.log("Response",response)
//             setDailyData(response?.data?.data);
//         } catch (error) {
//             //console.log("error", error);
//         }
//     };

//     useEffect(() => {
//         dailyReports();
//     }, []);


//  const sendNotificationFor =async()=>{
//     try {
//         const response= await sendEmailForDetectedFaces()
//     } catch (error) {
//         //console.log('error',error)
//     }
//  }

//     return (
//         <div>

// <Typography 
//   direction="row" 
//   style={{ 
//     marginTop: 20, 
//     display: 'flex', 
//     justifyContent: "left", 
//     fontWeight: 'bold', 
//     fontSize: '2.0rem', 
//     paddingLeft: isMobile ? "0px" : "10px",
//   }} 
//   gap={2}
// >
//   Dash Board
// </Typography>
//         <Stack direction={"row"} style={{ marginTop: 10, display: 'flex', justifyContent: "center" }} gap={2}>
          
//             {
//                 dailyData === undefined ?
//                     <CircularProgress  color="inherit"/> :
//                     <div >
//                       <Typography style={{color: theme.palette.primary.main, fontWeight: "bold",fontSize: '1.3rem',}}>
//                        Photographer  </Typography>
//                      <div style={{marginTop:8}}>
//                         <Grid container spacing={3}  >
//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
//                                     <div  style={{display:'flex',justifyContent:"space-between" }}>
//                                     <Stack direction={"column"}>
//                                         <Typography variant='h6'>Yearly registration </Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerThisYearPhoto}</span></Typography>
//                                     </Stack>
//                                     <div style={{ color: '#800000' }}>
//                                       <Icon size={30} icon={cameraRetro}/>
//                                     </div>
//                                    </div>
//                                 </Card>
//                             </Grid>


//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
//                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                     <Stack direction={"column"}>
//                                         <Typography variant='h6'>Monthly registration </Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerThisMonthPhoto}</span></Typography>
//                                     </Stack>
//                                     <div style={{ color: '#800000' }}>
//                                       <Icon size={30} icon={cameraRetro}/>
//                                     </div>
//                                    </div>
//                                 </Card>
//                             </Grid>


//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270,borderRadius:10 }} elevation={3}>
                                   
//                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                     <Stack direction={"column"}>
//                                         <Typography variant='h6'>Today registration </Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerTodayPhoto}</span></Typography>
//                                     </Stack>
//                                     <div style={{ color: '#800000' }}>
//                                       <Icon size={30} icon={cameraRetro}/>
//                                     </div>
//                                    </div>
//                                 </Card>

//                             </Grid>

//                           </Grid>

//                         </div>  
//                         <Typography style={{marginTop:15,color: theme.palette.secondary.main, fontWeight: "bold",fontSize: '1.3rem'}}>User</Typography>
//                         <div style={{marginTop:8}}>
//                         <Grid container spacing={3}>
//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
//                                      <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                      <Stack direction={"column"}>
//                                         <Typography variant='h6'>Yearly registration </Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerThisYearUser}</span></Typography>
//                                     </Stack>
//                                     <div style={{ color: '#F4A261' }}>
//                                       <Icon size={30} icon={user}/>
//                                     </div>
//                                    </div>
//                                 </Card>
//                             </Grid>


//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
                                  
//                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                      <Stack direction={"column"}>
//                                         <Typography variant='h6'>Monthly registration </Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerThisMonthUser}</span></Typography>
//                                     </Stack>
//                                     <div style={{ color: '#F4A261' }}>
//                                       <Icon size={30} icon={user}/>
//                                     </div>
//                                    </div>
//                                 </Card>

//                             </Grid>


//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270,borderRadius:10 }} elevation={3}>
//                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                      <Stack direction={"column"}>
//                                         <Typography variant='h6'>Today registration</Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerTodayUser}</span></Typography>
//                                     </Stack>
//                                     <div style={{ color: '#F4A261' }}>
//                                       <Icon size={30} icon={user}/>
//                                     </div>
//                                    </div>
//                                 </Card>

//                             </Grid>

//                         </Grid>
//                        </div>
                        
//                         <Typography style={{marginTop:15,color: "#28A745",fontWeight: "bold",fontSize: '1.3rem'}}>Album</Typography>
//                         <div style={{marginTop:8}}>
//                         <Grid container spacing={3}>
//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
//                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                         <Stack direction={"column"}>
//                                         <Typography variant='h6'>Yearly create </Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.eventsCreatedThisYear}</span></Typography>
//                                         </Stack>
//                                     <div style={{ color: '#556B2F' }}>
//                                       <Icon size={30} icon={book}/>
//                                     </div>
//                                    </div>
//                                 </Card>
//                             </Grid>


//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
//                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                          <Stack direction={"column"}>
//                                         <Typography variant='h6'>Monthly create</Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.eventsCreatedThisMonth}</span></Typography>
//                                        </Stack>
//                                     <div style={{ color: '#556B2F' }}>
//                                       <Icon size={30} icon={book}/>
//                                     </div>
//                                    </div>
//                                 </Card>

//                             </Grid>


//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270,borderRadius:10 }} elevation={3}>
//                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                     <Stack direction={"column"}>
//                                         <Typography variant='h6'>Today create </Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.eventsCreatedToday}</span></Typography>
//                                     </Stack>
//                                     <div style={{ color: '#556B2F' }}>
//                                       <Icon size={30} icon={book}/>
//                                     </div>
//                                    </div>
//                                 </Card>

//                             </Grid>

//                         </Grid>

//                         </div>
//                         <Typography style={{marginTop:15,fontSize: '1.3rem',color: "#FF5733",fontWeight: "bold"}}>Upload</Typography>
//                         <div style={{marginTop:8}}>
//                         <Grid container spacing={3}>
//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
//                                   <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                     <Stack direction={"column"}>
//                                         <Typography variant='h6'>Yearly upload </Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.uploadedImagesThisYear}</span></Typography>
//                                     </Stack>
//                                     <div style={{ color: '#778899' }}>
//                                       <Icon size={30} icon={cloudUpload}/>
//                                     </div>
//                                    </div>
//                                 </Card>
//                             </Grid>


//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
//                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                             <Stack direction={"column"}>
//                                                 <Typography variant='h6'>Monthly upload </Typography>
//                                                 <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.uploadedImagesThisMonth}</span></Typography>
//                                             </Stack>
//                                     <div style={{ color: '#778899' }}>
//                                       <Icon size={30} icon={cloudUpload}/>
//                                     </div>
//                                    </div>
//                                 </Card>

//                             </Grid>


//                             <Grid item xs={12} sm={6} md={6} lg={4}>
//                                 <Card style={{ padding: 20, width: 270,borderRadius:10 }} elevation={3}>
//                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
//                                       <Stack direction={"column"}>
//                                         <Typography variant='h6'>Today upload </Typography>
//                                         <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.uploadedImagesToday}</span></Typography>
//                                       </Stack>
//                                     <div style={{ color: '#778899' }}>
//                                       <Icon size={30} icon={cloudUpload}/>
//                                     </div>
//                                    </div>
//                                 </Card>

//                             </Grid>

//                         </Grid>

//                         </div>
//                     </div>
//             }
       
//         </Stack>
//         {/* <div style={{marginLeft:50,marginTop:10}}>
//            <Typography>Send Notification for the detected faces in last 24 hours</Typography>
//             <Button variant='outlined' onClick={sendNotificationFor} >Send Email</Button>
//             </div> */}
//         </div>

        
//     );
// }




  import {Card,CircularProgress,Stack,Typography,Grid,useMediaQuery,Button,} from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { getDailyReport, sendEmailForDetectedFaces } from "../../API/Admin";
  import Icon from "react-icons-kit";
  import { cloudUpload } from "react-icons-kit/fa/cloudUpload";
  import { cameraRetro } from "react-icons-kit/fa/cameraRetro";
  import { user } from "react-icons-kit/fa/user";
  import { book } from "react-icons-kit/fa/book";
  import { useTheme } from "@mui/material";
  import { useNavigate } from "react-router-dom";
  
  export default function DailyReport() {
    const [dailyData, setDailyData] = useState();
    const navigate = useNavigate();
  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
    const mediaScreen = useMediaQuery(theme.breakpoints.up("md"));
    
    const dailyReports = async () => {
      try {
        const response = await getDailyReport();
        setDailyData(response?.data?.data);
      } catch (error) {}
    };
  
    useEffect(() => {

      dailyReports();

    }, []);
  
    const sendNotificationFor = async () => {
      try {
        const response = await sendEmailForDetectedFaces();
      } catch (error) {}
    };
  
    const handleNavigate = (path) => {
      navigate(path);
    };

    const getMonthName = (monthIndex) => {
      const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
      ];
      return months[monthIndex];
    };
  
    const gridItems = [

      // Photographer section

      {
        section: "Photographer",
        color: theme.palette.primary.main,
        items: [
          {
            title: "Yearly registration",
            value: dailyData?.registerThisYearPhoto,
            path: "/photographerYearwiseList",
            icon: cameraRetro,
            iconColor: "#800000",
          },
          {
            title: `Monthly registration for ${getMonthName(new Date().getMonth())}`,
            value: dailyData?.registerThisMonthPhoto,
            path: "/photographerYearwiseList",
            icon: cameraRetro,
            iconColor: "#800000",
            //${getMonthName(new Date().getMonth())}
          },
          {
            title: "Today registration",
            value: dailyData?.registerTodayPhoto,
            path: "/photographerYearwiseList",
            icon: cameraRetro,
            iconColor: "#800000",
          },
        ],
      },
      // User section
      {
        section: "User",
        color: theme.palette.secondary.main,
        items: [

          {
            title: "Yearly registration",
            value: dailyData?.registerThisYearUser,
            path: "/userYearwiseData",
            icon: user,
            iconColor: "#F4A261",
          },

          {
            title: `Monthly registration for ${getMonthName(new Date().getMonth())}`,
            value: dailyData?.registerThisMonthUser,
            path: "/userYearwiseData",
            icon: user,
            iconColor: "#F4A261",
          },
          {
            title: "Today registration",
            value: dailyData?.registerTodayUser,
            path: "/userYearwiseData",
            icon: user,
            iconColor: "#F4A261",
          },
        ],
      },
      // Album section
      {
        section: "Album",
        color: "#28A745",
        items: [
          {
            title: "Yearly create",
            value: dailyData?.eventsCreatedThisYear,
            path: "/albumYearwiseList",
            icon: book,
            iconColor: "#556B2F",
          },
          {
            title:`Monthly create for ${getMonthName(new Date().getMonth())}`,
            value: dailyData?.eventsCreatedThisMonth,
            path: "/albumYearwiseList",
            icon: book,
            iconColor: "#556B2F",
          },
          {
            title: "Today create",
            value: dailyData?.eventsCreatedToday,
            path: "/albumYearwiseList",
            icon: book,
            iconColor: "#556B2F",
          },
        ],
      },
      // Upload section
      {
        section: "Upload",
        color: "#FF5733",
        items: [
          {
            title: "Yearly upload",
            value: dailyData?.uploadedImagesThisYear,
           // path: "/uploads/yearly",
            icon: cloudUpload,
            iconColor: "#778899",
          },
          {
            title: `Monthly upload for ${getMonthName(new Date().getMonth())}`,
            value: dailyData?.uploadedImagesThisMonth,
           // path: "/uploads/monthly",
            icon: cloudUpload,
            iconColor: "#778899",
          },
          {
            title: "Today upload",
            value: dailyData?.uploadedImagesToday,
           // path: "/uploads/today",
            icon: cloudUpload,
            iconColor: "#778899",
          },
        ],
      },
    ];
  
    return (
      <div >
        <Typography
          direction="row"
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "left",
            fontWeight: "bold",
            fontSize: "2.0rem",
            paddingLeft: isMobile ? "0px" : "10px",
          }}
          gap={2}
        >
          Dash Board
        </Typography>
        <Stack
          direction={"row"}
          style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
          gap={2}
        >
          {dailyData === undefined ? (
            <CircularProgress color="inherit" />
          ) : (
            <div style={{ marginBottom: 30 }}>
              {gridItems.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <Typography
                    style={{
                      marginTop: sectionIndex === 0 ? 0 : 15,
                      color: section.color,
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {section.section}
                  </Typography>
                  <div style={{ marginTop: 8 }}>
                    <Grid container spacing={3}>
                      {section.items.map((item, itemIndex) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={itemIndex}>
                          <Card
                            style={{
                              padding: 20,
                              width: 270,
                              height:100,
                              borderRadius: 10,
                              cursor: "pointer",
                            }}
                            elevation={3}
                            onClick={() => handleNavigate(item.path)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Stack direction={"column"}>
                                <Typography variant="h6">{item.title}</Typography>
                                <Typography variant="h6">
                                  <span style={{ fontWeight: "bold" }}>
                                    {item.value}
                                  </span>
                                </Typography>
                              </Stack>
                              <div style={{ color: item.iconColor }}>
                                <Icon size={30} icon={item.icon} />
                              </div>
                            </div>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Stack>
      </div>
    );
  }
  
  
