// import {
//   Button,
//   Card,
//   Typography,
//   Grid,
//   Box,
//   TextField,
//   Paper,
//   Divider,
//   Stack,
//   isMuiElement,
//   Select,
//   MenuItem,
//   IconButton,
//   Alert,
//   CircularProgress,
// } from "@mui/material";
// import React, { useEffect, useRef, useState } from "react";
// import SearchIcon from "@mui/icons-material/Search";
// import InputBase from "@mui/material/InputBase";
// import { styled, alpha, ThemeProvider } from "@mui/material/styles";
// import Modal from "@mui/material/Modal";
// import { useLocation, useNavigate } from "react-router";
// import guruji from "../../images/mountains.jpg";

// import {
//   CreateEvent,
//   EditEvent,
//   EditEvents,
//   GetEventApi,
//   JoinEventApi,
//   deleteMyEvent,
//   getPrivateGroup,
//   getProfileApi,
// } from "../../API/Photographer";
// import CollectionsIcon from "@mui/icons-material/Collections";
// import AddLocationIcon from "@mui/icons-material/AddLocation";
// import PublicIcon from "@mui/icons-material/Public";
// import ApartmentIcon from "@mui/icons-material/Apartment";
// import GroupIcon from "@mui/icons-material/Group";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import ScheduleIcon from "@mui/icons-material/Schedule";

// // import Loader from '../Component/loader';
// import Loader from "../Component/loader";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import "../../css/Home.css";
// import { Clear, Delete, Edit, SettingsCellRounded } from "@mui/icons-material";
// import DeleteModal from "../Component/deleteModal";
// import { SearchField } from "../Component/SerachField";
// import RangeDatePicker from "../Component/DatePicker";
// import FilterListIcon from "@mui/icons-material/FilterList";
// import CardEvent from "../DynmicaComponment/CardEvent";
// import MyCardEvent from "../DynmicaComponment/myCardEvent";
// import createAlbumICon from "../../images/create-album-icon.svg";
// import createAlbumIConwhite from "../../images/create-album-icon - white.svg";
// import asd from "../../images/date-time-slot-icon.svg";

// export default function Groups() {
//   const [SuccessMessage, setSuccessMessage] = useState({
//     message: "",
//     status: null,
//   });
//   const [open, setOpen] = React.useState(false);
//   const [isCreate, setIsCreate] = React.useState(false);
//   const [geteventName, setGetEventName] = useState([]);

//   const [eventName, setEventName] = useState("");
//   const [editedData, setEditedData] = useState("");
//   const [venue, setVenue] = useState("Select");
//   // const [location, setLocation] = useState('');
//   const [country, setCountry] = useState("India");
//   const [cityName, setCityName] = useState("Select");
//   const photographerEmail = localStorage.getItem("email");
//   const [eventDate, setEventDate] = useState("");
//   const [albumName, setAlbumName] = useState("");
//   const [eventProfile, setEventProfile] = useState("");
//   const [area, setArea] = useState("Select");
//   const [venueAl, setVenueAl] = useState("Select");
//   const [error, setError] = useState("");
//   const [load, setLoad] = useState(false);
//   const [isEditData, setIsEdit] = useState(false);
//   const [group, setGroup] = useState("Select");
//   const [slot, setSlot] = useState("Select");
//   const [joinGroup, setJoinGroup] = useState("");
//   const [status, setStatus] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate();
//   const [copiedMap, setCopiedMap] = useState({});
//   const [copiedLink, setCopiedLink] = useState({});
//   const theme = useTheme();
//   const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
//   const inIpdaAir = useMediaQuery(theme.breakpoints.down("820"));
//   const [filterDate, setFilterDate] = useState("");
//   const [opens, setOpens] = useState(false);
//   const [deleteItem, setDeleteItem] = useState("");
//   const [grouping, setGrouping] = useState("");
//   const [searchValue, setSearchValue] = useState("");
//   const [loads, setLoads] = useState(false);
//   const [loadss, setLoadss] = useState(false);
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const location = useLocation();
//   const [eventCode, setEventcode] = useState("");
//   const defaultImage = "https://pbs.twimg.com/media/CW6hXDiUoAArGhx.jpg";
//   const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

//   const [isOtherVenueCategory, setIsOtherVenueCategory] = useState(false);
//   const [isOtherPlace, setIsOtherPlace] = useState(false);
//   const [isOtherVenue, setIsOtherVenue] = useState(false);
//   const [useDefaultImage, setUseDefaultImage] = useState(false);

//   const [albumNameError, setAlbumNameError] = useState("");

//   function handlegroup(data) {
//     //console.log(data);
//     setGrouping(data);
//   }

//   const fileHandle = (event) => {
//     setErrorMessage("");
//     const file = event?.target?.files[0];
//     //  setEventProfile(event?.target?.files[0])
//     //console.log("file", file);
//     if (file) {
//       const fileType = file.type;

//       if (fileType.startsWith("image/")) {
//         setEventProfile(file);
//       } else {
//         setEventProfile(file);
//         setErrorMessage("Please select an image file.");
//       }
//     }
//   };

//   //console.log("groupe", group);

//   const editEvent = async (item) => {
//     setIsEdit(true);
//     //console.log("Editing Item:", item);
//     setEditedData(item);

//     // setEventName(item?.name);
//     // setVenue(item?.name);
//     // setGroup(item?.eventType);
//     // setVenueAl(item?.venueAlias);
//     // setSlot(item?.slot);
//     // setCountry(item?.city);
//     // setEventDate(item?.date);
//     // setAlbumName(item?.albumName);
//     // setEventcode(item?.eventCode);

//     setEventName(item?.name || "");
//     setVenue(item?.name || "");
//     setGroup(item?.eventType || "Select");
//     setVenueAl(item?.venueAlias || "Select");
//     setSlot(item?.slot || "Select");
//     setCountry(item?.country || "");
//     setCityName(item?.city || "Select");
//     setEventDate(item?.date || "");
//     setAlbumName(item?.albumName || "");
//     setEventcode(item?.eventCode || "");

//     setIsOtherVenue(!["Secretariat Office A1", "Shiva Temple", "Yoga School"].includes(item?.name || ""));
//     setIsOtherPlace(!locations.some(location => location.value === item?.city));
//     setIsOtherVenueCategory(!["Darshan Line", "Wedding Event", "Others"].includes(item?.venueAlias || ""));

//     const date = item.eventDate;
//     const parts = date.split("-");
//     const year = parts[0];
//     const month = parts[1];
//     const day = parts[2];
//     const formattedDate = `${year}-${month}-${day}`;

//     setEventDate(formattedDate);
//     setOpen(true);

//     // Call the EditEvents API function
//     try {
//       const response = await EditEvents(item);
//       //console.log("API Response:", response);
//       // Handle the response as needed
//     } catch (error) {
//       console.error("API Error:", error);
//       // Handle the error as needed
//     }
//   };

//   const handleSlotChange = (event) => {
//     setSlot(event.target.value);
//     setError("");
//     setErrorMessage("");
//     // if (event.target.value === "Select") {
//     //   setError("Slot is required");
//     // } else {
//     //   setError("");
//     // }
//   };

//   const [citys, countrys] = cityName.split(", ");

//   const handleEventSubmit = async () => {
//     const formData = new FormData();

//     try {
//       if (group === "Select") {
//         setErrorMessage("Album type is required");
//       } else if (venue === "Select") {
//         setErrorMessage("Venue is required");
//       } else if (venueAl === "Select") {
//         setErrorMessage("Venue category is required");
//       } else if (slot === "Select") {
//         setErrorMessage("Slot is required");
//       } else if (cityName === "Select") {
//         setErrorMessage("Place is required");
//       } else if (eventDate === "") {
//         setErrorMessage("Event Date is required");
//       } else if (albumName === "") {
//         setErrorMessage("Album Name is required");
//       } else {
//         setLoads(true);
//         setError("");
//         setLoad(true);
//         let response = "";

//         const userData = {
//           name: venue || "Default Venue",
//           albumName: albumName,
//           eventType: group,
//           eventDate: eventDate,
//           photographerEmail,
//           city: citys,
//           slot: slot,
//           venueAlias: venueAl,
//           area: area,
//           country: countrys,
//           eventCode: eventCode,
//         };
        
//         formData.append("data", JSON.stringify(userData));

//         if (eventProfile) {
//           formData.append("file", eventProfile, eventProfile?.name);
//         }

//         if (isEditData) {
//           // Assuming `eventCode` is available in `editedData`
//           formData.append("eventCode", editedData.eventCode);
//           response = await EditEvents(formData);
//         } else {
//           response = await CreateEvent(formData);
//         }

//         setLoad(false);
//         setErrorMessage(response?.data?.Message);
//         setStatus(response?.status);
//         if (response?.status === 200) {
//           // Reset form fields
//           setEventName("");
//           setEventDate("");
//           setGroup("Select");
//           setAlbumName("");
//           setSlot("");
//           setArea("");
//           setVenue("Select");
//           setVenueAl("Select");
//           setCountry("");
//           setCityName("Select");
//           setEventProfile("");
//           setTimeout(() => {
//             setOpen(false);
//             setErrorMessage("");
//             setStatus("");
//             setIsCreate(false);
//           }, 2000);
//         } else {
//           setErrorMessage(response?.response?.data?.Message);
//         }
//         getEvent();
//       }
//     } catch (err) {
//       //console.log(err);
//       setErrorMessage("An error occurred while creating the event.");
//     } finally {
//       setLoads(false);
//     }
//   };

//   const handleAlbumNameChange = (e) => {
//     const name = e.target.value;
//     if (name.length <= 25) {
//       setAlbumName(name);
//       setAlbumNameError(""); // Clear any previous error when within limit
//     } else {
//       setAlbumNameError("Album name cannot exceed 25 characters.");
//     }
//   };

//   const handleCopyFallback = (text) => {
//     const input = document.createElement("textarea");
//     input.value = text;
//     document.body.appendChild(input);
//     input.select();
//     document.execCommand("copy");
//     document.body.removeChild(input);
//   };

//   const handleCopylink = async (value, index) => {
//     try {
//       const referralLink = value;
//       if (navigator.clipboard && navigator.clipboard.writeText) {
//         await navigator.clipboard.writeText(referralLink);
//         setCopiedMap((prevState) => ({
//           ...prevState,
//           [index]: true,
//         }));
//       } else {
//         handleCopyFallback(referralLink);
//         setCopiedMap((prevState) => ({
//           ...prevState,
//           [index]: true,
//         }));
//       }
//     } catch (error) {
//       console.error("Error copying text: ", error);
//     } finally {
//       setTimeout(() => {
//         setCopiedMap((prevState) => ({
//           ...prevState,
//           [index]: false,
//         }));
//       }, 2000);
//     }
//   };

//   const handleAlbumLink = async (value, index) => {
//     try {
//       const referralLink = value;
//       if (navigator.clipboard && navigator.clipboard.writeText) {
//         await navigator.clipboard.writeText(referralLink);
//         setCopiedLink((prevState) => ({
//           ...prevState,
//           [index]: true,
//         }));
//       } else {
//         handleCopyFallback(referralLink);
//         setCopiedLink((prevState) => ({
//           ...prevState,
//           [index]: true,
//         }));
//       }
//     } catch (error) {
//       console.error("Error copying text: ", error);
//     } finally {
//       setTimeout(() => {
//         setCopiedLink((prevState) => ({
//           ...prevState,
//           [index]: false,
//         }));
//       }, 2000);
//     }
//   };

//   const getProfile = async () => {
//     const response = await getProfileApi();

//     //console.log("response123", response?.data?.data?.Events);
//     // getJoinEvents(response?.data?.data?.Events)
//   };
//   const handleEvents = async () => {
//     //console.log("joinGroup", joinGroup);
//     if (joinGroup === "") {
//       setError("Album code is required");
//     } else {
//       const data = {
//         eventCode: joinGroup,
//       };

//       const response = await JoinEventApi(data);
//       //console.log("response", response);
//       //console.log("response", response?.data?.Message);
//       setStatus(response?.data?.ErrorCode);
//       setErrorMessage(response?.data?.Message);
//       if (response?.data?.ErrorCode === 0) {
//         getProfile();

//         setTimeout(() => {
//           setOpen(false);
//           setErrorMessage("");
//           setJoinGroup("");
//         }, 2000);

//         // getProfile()
//       }
//     }
//   };

//   const handleChange = (e) => {
//     setGroup(e.target.value);
//     setError("");
//     setErrorMessage("");
//   };
//   const handleOpenForCreate = () => {
//     // setIsCreate(true)
//     setEventDate("");
//     setEventName("");
//     setOpen(true);
//     setIsCreate(true);
//   };

//   const handleOpenForJoin = () => {
//     setIsCreate(false);
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//     setIsCreate(false);
//     setJoinGroup("");
//     setError("");
//     setGroup("Select");
//     setSlot("Select");
//     setErrorMessage("");
//     setStatus("");
//     setIsEdit(false);
//     setEventName("");
//     setEventDate("");

//     // setArea('')
//     setVenue("Select");
//     setVenueAl("Select");
//     setCountry("");
//     setCityName("Select");
//     setEventProfile("");
//     setAlbumName("");
//     setIsOtherVenue(false);
//     setIsOtherVenueCategory(false);
//     setIsOtherPlace(false);
//     setUseDefaultImage(false);

//     setErrorMessage("");
//   };

//   const Search = styled("div")(({ theme }) => ({
//     position: "relative",
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 0.15),
//     borderColor: "#8CBAE8", // Set border color to black
//     borderWidth: 1, // Set border width
//     borderStyle: "solid", // Set border style
//     "&:hover": {
//       backgroundColor: alpha(theme.palette.common.white, 0.25),
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//       marginLeft: theme.spacing(3),
//       width: "auto",
//     },
//   }));

//   const SearchIconWrapper = styled("div")(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: "100%",
//     position: "absolute",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   }));

//   const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: "black",
//     borderColor: "black",
//     "& .MuiInputBase-input": {
//       padding: theme.spacing(1, 1, 1, 0),
//       // vertical padding + font size from searchIcon
//       paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//       transition: theme.transitions.create("width"),
//       width: "100%",
//       [theme.breakpoints.up("md")]: {
//         width: "20ch",
//       },
//     },
//   }));

//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     // width: 400,
//     height: "480px",
//     bgcolor: "background.paper",
//     borderRadius: 2,
//     boxShadow: 24,
//     p: 4,
//   };

//   const UserList = (item) => {
//     const eventProps = item;
//     navigate("/upload", { state: { eventProps } });
//   };

//   useEffect(() => {
//     const urlId = new URLSearchParams(location.search).get("event");
//     console.log("Event ID:", urlId);

//     const fetchData = async () => {
//       try {
//         if (urlId) {
//           const dates = {
//             startDate: "",
//             endDate: "",
//             search: urlId,
//           };
//           const res = await getEvent(dates); // Pass dates if `getEvent` needs it
//           console.log("Event Data:", res[0]);
//           const eventProps = res[0];

//           navigate("/upload", { state: { eventProps } });
//         } else {
//           const profile = await getProfile();
//           console.log("Profile Data:", profile);

//           const event = await getEvent(); // Call without parameters if needed
//           console.log("Default Event Data:", event);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [location]);

//   const getEvent = async (dates) => {
//     setLoadss(true);
//     try {
//       const response = await GetEventApi(dates);
//       console.log("hjsetGetEventNamesetGetEventNamehjh", response);
//       setGetEventName(response?.data?.data || []);
//       return response?.data?.data;
//     } catch (error) {
//       console.error("Error fetching event data:", error);
//     } finally {
//       setLoadss(false);
//     }
//   };

//   // const getPrivated = async (dates) => {
//   //   try {
//   //     const response = await getPrivateGroup(dates)
//   //     //console.log("responseuser", response)
//   //     setGetEventNamed(response?.data?.data)
//   //     //console.log(response?.data?.data, 'responsedata1234');
//   //   } catch (error) {
//   //   }
//   // }

//   // const getJoinEvents = async (item) => {
//   //   //console.log("item", item)
//   //   const data = item?.map(item => item.eventCode)

//   //   //console.log("data123", data)
//   //   const response = await getJoinGroup(data)
//   //   //console.log("response256", response)

//   //   setGetJoinEventName(response?.data?.data || [])
//   // }

//   const publicGroup = () => {
//     navigate("/publicGroup");
//   };

//   const payment = () => {
//     navigate("/subscription");
//   };
//   //----------------------Date filter ------------------//
//   const onDate = (data) => {
//     //console.log("fsdfgfdgdfgdfgdf", data);
//     const dat = data.split("#");
//     //console.log("hhh", dat);
//     const dates = {
//       startDate: dat[0],
//       endDate: dat[1],
//       search: "",
//     };

//     getEvent(dates);
//     getPrivateGroup(dates);
//   };
//   const today = new Date().toISOString().split("T")[0];
//   // startDate=2024-03-11&endDate=2024-03-11
//   // ----------------End Date filter----------------------//
//   // -----------------Delete MY events--------------------//
//   const DleteMyEvents = async (item) => {
//     try {
//       //console.log("kkkkkkkkkkkkkkkkkkkk");
//       const response = await deleteMyEvent(item);
//       //console.log("Deleteresponse:", response);
//       setEventProfile("");
//       setEventName("");
//       getEvent();
//       getPrivateGroup();
//       getProfile();
//       setDeleteItem("");
//       setSuccessMessage({
//         message: "Album deleted successfully",
//         status: true,
//       });
//       setTimeout(() => {
//         setSuccessMessage({ message: "", status: null });
//       }, 3000);
//     } catch (err) {
//       //console.log("Error in DleteMyEvents:", err);
//       setErrorMessage({ message: "Failed to delete event", status: false });
//       setTimeout(() => {
//         setErrorMessage({ message: "", status: null });
//       }, 3000);
//     }
//   };
//   //---------------end delete event---------------------------//
//   //----------------Edit My Event-----------------------------//

//   //----------------------------------------------------------//

//   const handleDeleteOpen = (item) => {
//     setOpens(true);
//     // DleteMyEvents
//     setDeleteItem(item);
//   };

//   const handledeleteclose = () => {
//     setDeleteItem("");
//     setOpens(false);
//   };

//   const handleAgree = () => {
//     DleteMyEvents(deleteItem);
//     setOpens(false);
//   };

//   //-------------------------------------------------------------//
//   //-----------------search onChange ----------------------------//

//   // useEffect(() => {
//   //   const data = {
//   //     startDate: "",
//   //     endDate: "",
//   //     search: searchValue
//   //   };
//   //   getEvent(data);
//   // }, [searchValue]);

//   // const handleSearchChange = (data) => {
//   //   //console.log("data", data);
//   //   const urlPrefix = "http://localhost:3000";

//   // // Check if `data` starts with `urlPrefix`, and if so, remove it
//   // const processedData = data.startsWith(urlPrefix) ? data.replace(urlPrefix, "") : data;

//   // setSearchValue(processedData);
//   //   // setSearchValue(data);
//   //   const datas = {
//   //     startDate: "",
//   //     endDate: "",
//   //     search: processedData,

//   //   };
//   //   //console.log(data);

//   //   getEvent(datas);
//   // };

//   const handleSearchChange = (data) => {
//     const datas = {
//       startDate: "",
//       endDate: "",
//       search: data,
//     };

//     getEvent(datas);
//   };

//   //------------------------------------------------------------//
//   //---------------sticky scroll Header -----------------------//
//   const [isScrolling, setIsScrolling] = useState(false);

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const isSmallScreen = useMediaQuery("(max-width:750px)");

//   const handleScroll = () => {
//     setIsScrolling(true);
//     let scrollTimeout;
//     clearTimeout(scrollTimeout);
//     scrollTimeout = setTimeout(() => {
//       setIsScrolling(false);
//     }, 200);
//   };
//   //-----------------------------------------------------------------------//

//   //console.log("area", area);
//   const handleLocationChange = (event) => {
//     const selectedValue = event.target.value;
//     const selectedLocation = locations.find(
//       (location) => location.value === selectedValue
//     );
//     setArea(selectedLocation?.display || "");
//     setCityName(selectedValue);
//     setError("");
//     setErrorMessage("");
//   };

//   const locations = [
//     { display: "Select", value: "Select" },
//     { display: "Bengaluru, India", value: "Bengaluru, India" },
//     { display: "Canada", value: "Canada" },
//     { display: "Cochin, India", value: "Cochin, India" },
//     { display: "Europe", value: "Europe" },
//     { display: "Europe - Amsterdam", value: "Amsterdam, Netherlands" },
//     { display: "Europe - Bad Antogast", value: "Bad Antogast, Germany" },
//     { display: "Europe - French Riviera", value: "French Riviera, France" },
//     { display: "New Delhi, India", value: "New Delhi, India" },
//     {
//       display: "Thiruvananthapuram, India",
//       value: "Thiruvananthapuram, India",
//     },
//     { display: "USA - Bay Area", value: "Bay Area, USA" },
//     { display: "USA - Boone", value: "Boone, USA" },
//     { display: "USA - DC", value: "DC, USA" },
//     { display: "USA - Los Angeles", value: "Los Angeles, USA" },
//   ];

//   const formatDate = (date) => {
//     const today = new Date();
//     const eventDate = new Date(date);

//     const isToday = today.toDateString() === eventDate.toDateString();
//     const isYesterday =
//       today.getDate() - 1 === eventDate.getDate() &&
//       today.getMonth() === eventDate.getMonth() &&
//       today.getFullYear() === eventDate.getFullYear();

//     const diffInTime = today.getTime() - eventDate.getTime();
//     const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

//     const isThisMonth =
//       today.getMonth() === eventDate.getMonth() &&
//       today.getFullYear() === eventDate.getFullYear();

//     const isThisYear = today.getFullYear() === eventDate.getFullYear();

//     if (isToday) return "Today";
//     if (isYesterday) return "Yesterday";
//     if (diffInDays <= 7) return "Last 7 days";
//     if (isThisMonth) return "This Month";
//     if (isThisYear) return "This Year";

//     return "Earlier";
//   };

//   const groupByDate = (events) => {
//     return events.reduce((acc, event) => {
//       const dateGroup = formatDate(event.eventDate);
//       if (!acc[dateGroup]) {
//         acc[dateGroup] = [];
//       }
//       acc[dateGroup].push(event);
//       return acc;
//     }, {});
//   };

//   const [groupedEvents, setGroupedEvents] = useState({});

//   useEffect(() => {
//     setGroupedEvents(groupByDate(geteventName));
//   }, [geteventName]);

//   //console.log('group event 1234', groupedEvents);

//   const renderEvents = () => {
//     if (loadss) {
//       return (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "50vh", // Ensure the height covers the main content area
//           }}
//         >
//           <CircularProgress color="inherit" />
//         </div>
//       );
//     }

//     // Check if there are any events in groupedEvents
//     const hasEvents = Object.keys(groupedEvents).length > 0;

//     if (!hasEvents) {
//       return (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             // alignItems: "center",
//             width: "100%",
//             paddingTop: "45px",
//           }}
//         >
//           <div
//           // style={{
//           //   display: "flex",
//           //   justifyContent: "center",
//           //   alignItems: "center",
//           // }}
//           >
//             <Typography variant="h6" color="textSecondary">
//               No Results Found
//             </Typography>
//           </div>
//         </div>
//       );
//     }

//     return Object.entries(groupedEvents).map(([date, events], dateIndex) => (
//       <React.Fragment key={dateIndex}>
//         <Grid item xs={12}>
//           <Typography
//             variant="body1"
//             style={{
//               paddingLeft: "35px",
//               textAlign: "center",
//               fontWeight: "bold",
//               display: "flex",
//               width: "100px",
//             }}
//           >
//             {date}
//           </Typography>
//         </Grid>
//         {events.map((item, index) => (
//           <Grid item xs={12} sm={6} md={3} key={index}>
//             <ThemeProvider theme={theme}>
//               <Stack
//                 direction="column"
//                 alignItems="center"
//                 sx={{ width: "100%" }}
//               >
//                 <div
//                   style={{
//                     paddingTop: "5px",
//                     height: "100%",
//                     overflow: "hidden",
//                     paddingBottom: "40px",
//                     width: "100%", // Ensure the inner content doesn't exceed its container
//                   }}
//                 >
//                   <MyCardEvent
//                     item={item}
//                     onCardClick={UserList}
//                     defaultImage={defaultImage}
//                     editEvent={editEvent}
//                     handleDeleteOpen={handleDeleteOpen}
//                     key={item.eventCode}
//                     index={index}
//                     copiedMap={copiedMap}
//                     handleCopylink={handleCopylink}
//                     albumCopy={handleAlbumLink}
//                     albumCopied={copiedLink}
//                     useDefaultImage={useDefaultImage}
//                   />
//                 </div>
//               </Stack>
//             </ThemeProvider>
//           </Grid>
//         ))}
//       </React.Fragment>
//     ));
//   };

//   return (
//     <div>
//       {load ? <Loader open={load} /> : null}
//       <DeleteModal
//         open={opens}
//         handleClose={handledeleteclose}
//         handleAgrreDelete={handleAgree}
//       />

//       <Modal
//         open={open}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         BackdropProps={{ onClick: handleClose }}
//       >
//         <Box
//           sx={{
//             ...style,
//             width: isMobile ? "90%" : isTablet ? "70%" : 400,
//             maxHeight: "80vh",
//             overflowY: "auto",
//             overflowX: "hidden",
//             padding: isMobile ? 2 : 3, // Add padding for mobile view
//           }}
//         >
//           <Stack style={{ position: "absolute", right: 10, top: 10 }}>
//             <Box onClick={handleClose}>
//               <Clear />
//             </Box>
//           </Stack>
//           <Stack
//             style={{
//               display: "flex",
//               zIndex: 1,
//               position: "sticky",
//               top: 0,
//               padding: "8px 0",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             {loads && <CircularProgress color="inherit" />}
//           </Stack>

//           {errorMessage && (
//             <Stack
//               style={{
//                 display: "flex",
//                 zIndex: 1,
//                 position: "sticky",
//                 top: 0,
//                 padding: "8px 0",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//             >
//               <Alert severity={status === 200 ? "success" : "error"}>
//                 {errorMessage}
//               </Alert>
//             </Stack>
//           )}

//           <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
//             <Typography style={{ fontSize: "18px", color: "black" }}>
//               Album Type{" "}
//             </Typography>
//             <Select value={group} onChange={handleChange}>
//               <MenuItem value={"Select"}>Select</MenuItem>
//               <MenuItem value={"Public"}>Public</MenuItem>
//               <MenuItem value={"Private"}>Private</MenuItem>
//             </Select>
//             {error === "Album type is required" && (
//               <Typography
//                 style={{ color: "red", fontSize: "16px", textAlign: "left" }}
//               >
//                 {error}
//               </Typography>
//             )}
//           </Stack>

//           {/* <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
//             <Typography style={{ fontSize: "18px", color: "black" }}>
//               Album Name{" "}
//             </Typography>
//             <TextField
//               label="Album Name"
//               value={albumName}
//               onChange={(e) => setAlbumName(e.target.value)}
//             />
//             {error === "Album name is required" && (
//               <Typography
//                 style={{ color: "red", fontSize: "16px", textAlign: "left" }}
//               >
//                 {error}
//               </Typography>
//             )}
//           </Stack> */}

//           <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
//             <Typography style={{ fontSize: "18px", color: "black" }}>
//               Album Name{" "}
//             </Typography>
//             <TextField
//               label="Album Name"
//               value={albumName}
//               onChange={handleAlbumNameChange}
//               error={Boolean(albumNameError)} // Show error state if there's an error
//               helperText={albumNameError || `${albumName?.length}/25`} // Show error or character count
//             />
//             {error === "Album name is required" && (
//               <Typography
//                 style={{ color: "red", fontSize: "16px", textAlign: "left" }}
//               >
//                 {error}
//               </Typography>
//             )}
//           </Stack>

//           <Stack spacing={1} style={{ marginBottom: "6px" }}>
//             <Typography style={{ fontSize: "18px", color: "black" }}>
//               Venue{" "}
//             </Typography>
//             {isOtherVenue ? (
//               // Show TextField if 'Other' is selected
//               <TextField
//                 placeholder="Type venue name"
//                 value={venue}
//                 onChange={(e) => {
//                   const inputValue = e.target.value;
//                   setVenue(inputValue);
//                   setError("");
//                   setErrorMessage("");

//                   // If the user clears the TextField, switch back to dropdown
//                   if (inputValue.trim() === "") {
//                     setIsOtherVenue(false);
//                     setVenue("Select");
//                   }
//                 }}
//                 onBlur={(e) => {
//                   if (e.target.value.trim() === "") {
//                     setIsOtherVenue(false); // Return to dropdown on blur if empty
//                   }
//                 }}
//                 style={{ width: "100%" }}
//               />
//             ) : (
//               // Show Select dropdown for predefined options
//               <Select
//                 value={venue}
//                 onChange={(e) => {
//                   const selectedValue = e.target.value;
//                   // setVenue(selectedValue);
//                   // setError("");
//                   // setErrorMessage("");

//                   if (selectedValue === "Other") {
//                     setIsOtherVenue(true); // Switch to text field
//                     setVenue(""); // Clear the venue for manual input
//                   } else {
//                     setVenue(selectedValue);
//                     setIsOtherVenue(false); // Stay with dropdown
                     
//                   }
//                   setError("");
//                   setErrorMessage("");
//                 }}
//               >
//                 <MenuItem value={"Select"}>Select</MenuItem>
//                 <MenuItem value={"Secretariat Office A1"}>
//                   Secretariat Office A1
//                 </MenuItem>
//                 <MenuItem value={"Special Enclosure - Shiva Temple"}>
//                   Special Enclosure - Shiva Temple
//                 </MenuItem>
//                 <MenuItem value={"Yoga School"}>Yoga School</MenuItem>
//                 <MenuItem value={"Shiva Temple"}>Shiva Temple</MenuItem>
//                 <MenuItem value={"Satsang Backstage"}>
//                   Satsang Backstage
//                 </MenuItem>
//                 <MenuItem value={"Gurukul"}>Gurukul</MenuItem>
//                 <MenuItem value={"Dhyan Mandir"}>Dhyan Mandir</MenuItem>
//                 <MenuItem value={"Visakha Laxmi Mantap - At day"}>
//                   Visakha Laxmi Mantap - At day
//                 </MenuItem>
//                 <MenuItem value={"Visakha Laxmi Mantap - At night"}>
//                   Visakha Laxmi Mantap - At night
//                 </MenuItem>
//                 <MenuItem value={"Radha Kunjh"}>Radha Kunjh</MenuItem>
//                 <MenuItem value={"Special Meet"}>Special Meet</MenuItem>
//                 <MenuItem value={"Guru Padukavanam"}>Guru Padukavanam</MenuItem>
//                 <MenuItem value={"Other"}>Other</MenuItem>
//               </Select>
//             )}
//             {error === "Venue is required" && (
//               <Typography
//                 style={{ color: "red", fontSize: "16px", textAlign: "left" }}
//               >
//                 {error}
//               </Typography>
//             )}
//           </Stack>

//           <Stack spacing={1} style={{ marginBottom: "6px" }}>
//   <Typography style={{ fontSize: "18px", color: "black" }}>
//     Venue Category{" "}
//   </Typography>
//   {isOtherVenueCategory ? (
//     <TextField
//       placeholder="Type venue category"
//       value={venueAl}
//       onChange={(e) => {
//         const inputValue = e.target.value;
//         setVenueAl(inputValue);
//         setError("");
//         setErrorMessage("");

//         // Return to dropdown if input is cleared

//         if (inputValue.trim() === "") {
//           setIsOtherVenueCategory(false);
//           setVenueAl("Select");
//         }
//       }}
//       onBlur={(e) => {
//         if (e.target.value.trim() === "") {
//           setIsOtherVenueCategory(false);
//         }
//       }}
//       style={{ width: "100%" }}
//     />
//   ) : (
//     <Select
//       value={venueAl}
//       onChange={(e) => {
//         const selectedValue = e.target.value;
//         setVenueAl(selectedValue);
//         setError("");
//         setErrorMessage("");

//         if (selectedValue === "Other") {
//           setIsOtherVenueCategory(true);
//           setVenueAl(""); // Clear previous value for manual input
//         } else {
//           setIsOtherVenueCategory(false);
//         }
//         setError("");
//         setErrorMessage("");
//       }}
//     >
//       <MenuItem value={"Select"}>Select</MenuItem>
//       <MenuItem value={"Darshan Line"}>Darshan Line</MenuItem>
//       <MenuItem value={"Wedding Event"}>Wedding Event</MenuItem>
//       <MenuItem value={"Other"}>Other</MenuItem>
//     </Select>
//   )}
//   {error === "Venue Category is required" && (
//     <Typography
//       style={{ color: "red", fontSize: "16px", textAlign: "left" }}
//     >
//       {error}
//     </Typography>
//   )}
// </Stack>


//           <Stack spacing={1} style={{ marginBottom: "6px" }}>
//             <Typography style={{ fontSize: "18px", color: "black" }}>
//               Slot
//             </Typography>
//             <Select value={slot} onChange={handleSlotChange}>
//               <MenuItem value={"Select"}>Select</MenuItem>
//               <MenuItem value={"Morning"}>Morning</MenuItem>
//               <MenuItem value={"Evening"}>Evening</MenuItem>
//               <MenuItem value={"Night"}>Night</MenuItem>
//             </Select>
//           </Stack>

//           <Stack spacing={1} style={{ marginBottom: "6px" }}>
//     <Typography style={{ fontSize: "18px", color: "black" }}>
//       Place
//     </Typography>
//     {isOtherPlace ? (
//       <TextField
//         placeholder="Type place name"
//         value={cityName}
//         onChange={(e) => {
//           const inputValue = e.target.value.trim();
//           setCityName(inputValue);
//           setError("");
//           setErrorMessage("");

//           // If the user clears the TextField, switch back to dropdown
//           if (inputValue === "") {
//             setIsOtherPlace(false);
//             setCityName("Select"); // Reset to default dropdown value
//           }
//         }}
//         onBlur={(e) => {
//           if (e.target.value.trim() === "") {
//             setIsOtherPlace(false);
//             setCityName("Select"); // Reset to default dropdown value
//           }
//         }}
//       />
//     ) : (
//       <Select
//         value={cityName}
//         onChange={(e) => {
//           const selectedValue = e.target.value;
//           if (selectedValue === "Other") {
//             setIsOtherPlace(true);
//             setCityName("");
//           } else {
//             setCityName(selectedValue);
//             setIsOtherPlace(false);
//           }
//           setError("");
//           setErrorMessage("");
//         }}
//       >
//         {locations.map((location, index) => (
//           <MenuItem key={index} value={location.value}>
//             {location.display}
//           </MenuItem>
//         ))}
//         <MenuItem value={"Other"}>Other</MenuItem>
//       </Select>
//     )}
//   </Stack>


//           <Stack spacing={1}>
//             <Typography
//               style={{ fontSize: "18px", color: "black", textAlign: "left" }}
//             >
//               Event Date{" "}
//             </Typography>
//             <TextField
//               placeholder="Select Event Date"
//               type="date"
//               value={eventDate}
//               onChange={(e) => {
//                 //console.log(e.target.value);
//                 setEventDate(e.target.value);
//                 setError("");
//                 setErrorMessage("");
//               }}
//               style={{ width: "100%" }}
//               inputProps={{ max: today }}
//             />
//             {error === "Event Date is required" && (
//               <Typography
//                 style={{ color: "red", fontSize: "16px", textAlign: "left" }}
//               >
//                 {error}
//               </Typography>
//             )}
//           </Stack>

//           <Button
//             variant="contained"
//             fullWidth
//             style={{ marginTop: 20 }}
//             onClick={handleEventSubmit}
//           >
//             {isEditData ? "Update" : "Create"}
//           </Button>
//         </Box>
//       </Modal>

//       <div
//         style={{
//           position: "sticky",
//           top: "60px",
//           zIndex: "999",
//           width: "100%",
//           // transition: "top 0.3s",
//           // top: isScrolling ? "-100px" : "70px",
//         }}
//       >
//         <div>
//           <Card
//             style={{
//               marginTop: 10,
//               width: "100%",
//               backgroundColor: "#F4FAFF",
//               // border: "1px solid #D4D4D4",
//             }}
//             elevation={0}
//           >
//             {isMobileOrTablet ? (
//               <Grid container spacing={2} sx={{ padding: "10px" }}>
//                 <Grid item xs={12} sm={12}></Grid>
//                 <Grid item xs={12} sm={12}>
//                   <RangeDatePicker width={"100%"} filterDate={onDate} />
//                 </Grid>
//                 <Grid
//                   item
//                   xs={12}
//                   sm={12}
//                   style={{
//                     display: "flex",
//                     justifyContent: "flex-end",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Button
//                     height="20px"
//                     fullWidth
//                     variant="text"
//                     onClick={handleOpenForCreate}
//                     style={{
//                       textTransform: "none",
//                       fontWeight: "600",
//                       backgroundColor: "white",
//                       border: "1px solid #D4D4D4",
//                     }}
//                   >
//                     <img
//                       style={{ marginRight: "10px" }}
//                       src={createAlbumICon}
//                       alt=""
//                       srcset=""
//                     />
//                     Create Album
//                   </Button>
//                   <IconButton aria-label="filter">
//                     {/* <FilterListIcon /> */}
//                   </IconButton>
//                   <SearchField
//                     width={"400px"}
//                     searchProps={handleSearchChange}
//                     placeholder=""
//                   />
//                   {/* <TextField onChange={handleChange} /> */}
//                 </Grid>
//               </Grid>
//             ) : (
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "flex-end",

//                   justifyContent: "space-between",
//                   padding: 5,
//                   // width: "100%"
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     padding: 10,
//                   }}
//                 >
//                   <Grid>
//                     <Button
//                       height="20px"
//                       fullWidth
//                       variant="text"
//                       onClick={handleOpenForCreate}
//                       style={{
//                         textTransform: "none",
//                         fontWeight: "600",
//                         backgroundColor: "white",
//                         border: "1px solid #D4D4D4",
//                       }}
//                     >
//                       <img
//                         style={{ marginRight: "10px" }}
//                         src={createAlbumICon}
//                         alt=""
//                         srcset=""
//                       />
//                       Create Album
//                     </Button>
//                   </Grid>
//                 </div>
//                 <div>
//                   <Stack
//                     direction={"row"}
//                     gap={2}
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "flex-end",
//                       padding: 10,
//                     }}
//                   >
//                     {" "}
//                     <RangeDatePicker width={"100%"} filterDate={onDate} />
//                     <SearchField
//                       width="400px"
//                       searchProps={handleSearchChange}
//                       // placeholder="Search, Album name"
//                       style={{ backgroundColor: "white" }}
//                     />
//                   </Stack>
//                 </div>
//               </div>
//             )}
//           </Card>
//           {/* <h2>sdfghj</h2> */}
//           {SuccessMessage.status && (
//             <Alert
//               severity={SuccessMessage.status ? "success" : "error"}
//               style={{ marginBottom: 10 }}
//             >
//               {SuccessMessage.message}
//             </Alert>
//           )}
//         </div>
//       </div>
//       <Stack
//         marginTop="60px"
//         marginLeft={2}
//         spacing={2}
//         alignItems={isMobile ? "flex-start" : "center"}
//       >
//         {/* First Section */}
//         <Stack
//           direction="column"
//           alignItems={isMobile ? "flex-start" : "center"}
//         >
//           {/* <PhotoAlbumIcon fontSize="large" /> */}
//           <Typography
//             sx={{
//               fontSize: "24px",
//               fontWeight: "600",
//               color: "#3974CB",
//               mt: 2,
//             }}
//           >
//             My Uploads
//           </Typography>
//         </Stack>

//         {/* Second Section */}
//         <Typography
//           variant="body1"
//           sx={{
//             fontSize: isSmallScreen ? "1em" : "0.em",
//             textAlign: isSmallScreen ? "left" : "center", // Corrected textAlign
//             mt: 5,
//             mb: 7,
//             maxWidth: 800,
//             paddingLeft: isSmallScreen ? "" : 0, // Adjusts padding on small screens
//           }}
//         >
//           Albums created by you are displayed below.
//         </Typography>

//         {/* Button Section */}
//         <Grid
//           container
//           justifyContent={isMobile ? "flex-start" : "center"} // Centers button on mobile
//         >
//           <Button
//             height="20px"
//             // fullWidth
//             variant="contained"
//             onClick={handleOpenForCreate}
//             style={{
//               // alignItems: "center",
//               // justifyContent: "center",
//               textTransform: "none",
//               fontWeight: "600",
//               color: "white",
//               backgroundColor: "#2167CF",
//             }}
//           >
//             <img
//               style={{ marginRight: "10px" }}
//               src={createAlbumIConwhite}
//               alt=""
//               srcSet=""
//             />
//             Create Album
//           </Button>
//         </Grid>
//       </Stack>

//       <div style={{ marginTop: "10px", position: "relative" }}>
//         {loadss ? (
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               paddingTop: "20px",
//             }}
//           >
//             <CircularProgress color="inherit" />
//           </div>
//         ) : (
//           <Box
//             maxWidth="1400px"
//             margin="0 auto"
//             padding={isMobileOrTablet ? 1 : 2}
//           >
//             <Grid container spacing={2} marginTop={isMobileOrTablet ? 1 : null}>
//               {renderEvents()}
//             </Grid>
//           </Box>
//         )}
//       </div>

//       {/* <div>{renderEvents(groupedEvents)}</div> */}
//     </div>
//   );
// }


                   //              previous  code               //

import {
  Button,
  Card,
  Typography,
  Grid,
  Box,
  TextField,
  Paper,
  Divider,
  Stack,
  isMuiElement,
  Select,
  MenuItem,
  IconButton,
  Alert,
  CircularProgress,
  Pagination,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha, ThemeProvider } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import { useLocation, useNavigate } from "react-router";
import guruji from "../../images/mountains.jpg";

import {
  CreateEvent,
  EditEvent,
  EditEvents,
  GetEventApi,
  JoinEventApi,
  deleteMyEvent,
  getPrivateGroup,
  getProfileApi,
} from "../../API/Photographer";
import CollectionsIcon from "@mui/icons-material/Collections";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import PublicIcon from "@mui/icons-material/Public";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupIcon from "@mui/icons-material/Group";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";

// import Loader from '../Component/loader';
import Loader from "../Component/loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "../../css/Home.css";
import { Clear, Delete, Edit, SettingsCellRounded } from "@mui/icons-material";
import DeleteModal from "../Component/deleteModal";
import { SearchField } from "../Component/SerachField";
import RangeDatePicker from "../Component/DatePicker";
import FilterListIcon from "@mui/icons-material/FilterList";
import CardEvent from "../DynmicaComponment/CardEvent";
import MyCardEvent from "../DynmicaComponment/myCardEvent";
import createAlbumICon from "../../images/create-album-icon.svg";
import createAlbumIConwhite from "../../images/create-album-icon - white.svg";
import asd from "../../images/date-time-slot-icon.svg";

export default function Groups() {
  const [SuccessMessage, setSuccessMessage] = useState({
    message: "",
    status: null,
  });
  const [open, setOpen] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [geteventName, setGetEventName] = useState([]);

  const [eventName, setEventName] = useState("");
  const [editedData, setEditedData] = useState("");
  const [venue, setVenue] = useState("Select");
  // const [location, setLocation] = useState('');
  const [country, setCountry] = useState("India");
  const [cityName, setCityName] = useState("Select");
  const photographerEmail = localStorage.getItem("email");
  const [eventDate, setEventDate] = useState("");
  const [albumName, setAlbumName] = useState("");
  const [eventProfile, setEventProfile] = useState("");
  const [area, setArea] = useState("Select");
  const [venueAl, setVenueAl] = useState("Select");
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const [isEditData, setIsEdit] = useState(false);
  const [group, setGroup] = useState("Select");
  const [slot, setSlot] = useState("Select");
  const [joinGroup, setJoinGroup] = useState("");
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [copiedMap, setCopiedMap] = useState({});
  const [copiedLink, setCopiedLink] = useState({});
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const inIpdaAir = useMediaQuery(theme.breakpoints.down("820"));
  const [filterDate, setFilterDate] = useState("");
  const [opens, setOpens] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [grouping, setGrouping] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loads, setLoads] = useState(false);
  const [loadss, setLoadss] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [eventCode, setEventcode] = useState("");
  const defaultImage = "https://pbs.twimg.com/media/CW6hXDiUoAArGhx.jpg";
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [isOtherVenueCategory, setIsOtherVenueCategory] = useState(false);
  const [isOtherPlace, setIsOtherPlace] = useState(false);
  const [isOtherVenue, setIsOtherVenue] = useState(false);
  const [useDefaultImage, setUseDefaultImage] = useState(false);
  const [albumNameError, setAlbumNameError] = useState("");

const [page,setPage] = useState(1);
const [totalPages,setTotalPages] = useState(1);
const eventsPerPage = 20;


  function handlegroup(data) {
    //console.log(data);
    setGrouping(data);
  }

  const fileHandle = (event) => {
    setErrorMessage("");
    const file = event?.target?.files[0];
    //  setEventProfile(event?.target?.files[0])
    //console.log("file", file);
    if (file) {
      const fileType = file.type;

      if (fileType.startsWith("image/")) {
        setEventProfile(file);
      } else {
        setEventProfile(file);
        setErrorMessage("Please select an image file.");
      }
    }
  
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  //console.log("groupe", group);

  const editEvent = async (item) => {
    setIsEdit(true);
    //console.log("Editing Item:", item);
    setEditedData(item);

    // setEventName(item?.name);
    // setVenue(item?.name);
    // setGroup(item?.eventType);
    // setVenueAl(item?.venueAlias);
    // setSlot(item?.slot);
    // setCountry(item?.city);
    // setEventDate(item?.date);
    // setAlbumName(item?.albumName);
    // setEventcode(item?.eventCode);

    setEventName(item?.name || "");
    setVenue(item?.name || "");
    setGroup(item?.eventType || "Select");
    setVenueAl(item?.venueAlias || "Select");
    setSlot(item?.slot || "Select");
    setCountry(item?.country || "");
    setCityName(item?.city || "Select");
    setEventDate(item?.date || "");
    setAlbumName(item?.albumName || "");
    setEventcode(item?.eventCode || "");

    setIsOtherVenue(!["Secretariat Office A1", "Shiva Temple", "Yoga School"].includes(item?.name || ""));
    setIsOtherPlace(!locations.some(location => location.value === item?.city));
    setIsOtherVenueCategory(!["Darshan Line", "Wedding Event", "Others"].includes(item?.venueAlias || ""));

    const date = item.eventDate;
    const parts = date.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    const formattedDate = `${year}-${month}-${day}`;

    setEventDate(formattedDate);
    setOpen(true);

    // Call the EditEvents API function
    try {
      const response = await EditEvents(item);
      //console.log("API Response:", response);
      // Handle the response as needed
    } catch (error) {
      console.error("API Error:", error);
      // Handle the error as needed
    }
  };

  const handleSlotChange = (event) => {
    setSlot(event.target.value);
    setError("");
    setErrorMessage("");
    // if (event.target.value === "Select") {
    //   setError("Slot is required");
    // } else {
    //   setError("");
    // }
  };

  const [citys, countrys] = cityName.split(", ");

  const handleEventSubmit = async () => {
    const formData = new FormData();

    try {
      if (group === "Select") {
        setErrorMessage("Album type is required");
      } else if (venue === "Select") {
        setErrorMessage("Venue is required");
      } else if (venueAl === "Select") {
        setErrorMessage("Venue category is required");
      } else if (slot === "Select") {
        setErrorMessage("Slot is required");
      } else if (cityName === "Select") {
        setErrorMessage("Place is required");
      } else if (eventDate === "") {
        setErrorMessage("Event Date is required");
      } else if (albumName === "") {
        setErrorMessage("Album Name is required");
      } else {
        setLoads(true);
        setError("");
        setLoad(true);
        let response = "";

        const userData = {
          name: venue || "Default Venue",
          albumName: albumName,
          eventType: group,
          eventDate: eventDate,
          photographerEmail,
          city: citys,
          slot: slot,
          venueAlias: venueAl,
          area: area,
          country: countrys,
          eventCode: eventCode,
        };
        
        formData.append("data", JSON.stringify(userData));

        if (eventProfile) {
          formData.append("file", eventProfile, eventProfile?.name);
        }

        if (isEditData) {
          // Assuming `eventCode` is available in `editedData`
          formData.append("eventCode", editedData.eventCode);
          response = await EditEvents(formData);
        } else {
          response = await CreateEvent(formData);
        }

        setLoad(false);
        setErrorMessage(response?.data?.Message);
        setStatus(response?.status);
        if (response?.status === 200) {
          // Reset form fields
          setEventName("");
          setEventDate("");
          setGroup("Select");
          setAlbumName("");
          setSlot("");
          setArea("");
          setVenue("Select");
          setVenueAl("Select");
          setCountry("");
          setCityName("Select");
          setEventProfile("");
          setTimeout(() => {
            setOpen(false);
            setErrorMessage("");
            setStatus("");
            setIsCreate(false);
          }, 2000);
        } else {
          setErrorMessage(response?.response?.data?.Message);
        }
        getEvent();
      }
    } catch (err) {
      //console.log(err);
      setErrorMessage("An error occurred while creating the event.");
    } finally {
      setLoads(false);
    }
  };

  const handleAlbumNameChange = (e) => {
    const name = e.target.value;
    if (name.length <= 25) {
      setAlbumName(name);
      setAlbumNameError(""); // Clear any previous error when within limit
    } else {
      setAlbumNameError("Album name cannot exceed 25 characters.");
    }
  };

  const handleCopyFallback = (text) => {
    const input = document.createElement("textarea");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  };

  const handleCopylink = async (value, index) => {
    try {
      const referralLink = value;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(referralLink);
        setCopiedMap((prevState) => ({
          ...prevState,
          [index]: true,
        }));
      } else {
        handleCopyFallback(referralLink);
        setCopiedMap((prevState) => ({
          ...prevState,
          [index]: true,
        }));
      }
    } catch (error) {
      console.error("Error copying text: ", error);
    } finally {
      setTimeout(() => {
        setCopiedMap((prevState) => ({
          ...prevState,
          [index]: false,
        }));
      }, 2000);
    }
    
  };

  const handleAlbumLink = async (value, index) => {
    try {
      const referralLink = value;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(referralLink);
        setCopiedLink((prevState) => ({
          ...prevState,
          [index]: true,
        }));
      } else {
        handleCopyFallback(referralLink);
        setCopiedLink((prevState) => ({
          ...prevState,
          [index]: true,
        }));
      }
    } catch (error) {
      console.error("Error copying text: ", error);
    } finally {
      setTimeout(() => {
        setCopiedLink((prevState) => ({
          ...prevState,
          [index]: false,
        }));
      }, 2000);
    }
  };

  const getProfile = async () => {
    const response = await getProfileApi();

    //console.log("response123", response?.data?.data?.Events);
    // getJoinEvents(response?.data?.data?.Events)
  };
  const handleEvents = async () => {
    //console.log("joinGroup", joinGroup);
    if (joinGroup === "") {
      setError("Album code is required");
    } else {
      const data = {
        eventCode: joinGroup,
      };

      const response = await JoinEventApi(data);
      //console.log("response", response);
      //console.log("response", response?.data?.Message);
      setStatus(response?.data?.ErrorCode);
      setErrorMessage(response?.data?.Message);
      if (response?.data?.ErrorCode === 0) {
        getProfile();

        setTimeout(() => {
          setOpen(false);
          setErrorMessage("");
          setJoinGroup("");
        }, 2000);

        // getProfile()
      }
    }
  };

  const handleChange = (e) => {
    setGroup(e.target.value);
    setError("");
    setErrorMessage("");
  };
  const handleOpenForCreate = () => {
    // setIsCreate(true)
    setEventDate("");
    setEventName("");
    setOpen(true);
    setIsCreate(true);
  };

  const handleOpenForJoin = () => {
    setIsCreate(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsCreate(false);
    setJoinGroup("");
    setError("");
    setGroup("Select");
    setSlot("Select");
    setErrorMessage("");
    setStatus("");
    setIsEdit(false);
    setEventName("");
    setEventDate("");

    // setArea('')
    setVenue("Select");
    setVenueAl("Select");
    setCountry("");
    setCityName("Select");
    setEventProfile("");
    setAlbumName("");
    setIsOtherVenue(false);
    setIsOtherVenueCategory(false);
    setIsOtherPlace(false);
    setUseDefaultImage(false);

    setErrorMessage("");
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    borderColor: "#8CBAE8", // Set border color to black
    borderWidth: 1, // Set border width
    borderStyle: "solid", // Set border style
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    borderColor: "black",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    height: "480px",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const UserList = (item) => {
    const eventProps = item;
    navigate("/upload", { state: { eventProps } });
  };

  useEffect(() => {
    const urlId = new URLSearchParams(location.search).get("event");
    console.log("Event ID:", urlId);

    const fetchData = async () => {
      try {
        if (urlId) {
          const dates = {
            startDate: "",
            endDate: "",
            search: urlId,
          };
          const res = await getEvent(dates); // Pass dates if `getEvent` needs it
          console.log("Event Data:", res[0]);
          const eventProps = res[0];

          navigate("/upload", { state: { eventProps } });
        } else {
          const profile = await getProfile();
          console.log("Profile Data:", profile);

          const event = await getEvent(); // Call without parameters if needed
          console.log("Default Event Data:", event);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location]);

  const getEvent = async (dates) => {
    setLoadss(true);
    try {
      const response = await GetEventApi(dates);
      console.log("hjsetGetEventNamesetGetEventNamehjh", response);
      setGetEventName(response?.data?.data || []);
      const allEvents = response?.data?.data || [];
      setTotalPages(Math.ceil(allEvents.length / eventsPerPage));
      setGetEventName(allEvents);
      return response?.data?.data;
    } catch (error) {
      console.error("Error fetching event data:", error);
    } finally {
      setLoadss(false);
    }
  };

  // const getPrivated = async (dates) => {
  //   try {
  //     const response = await getPrivateGroup(dates)
  //     //console.log("responseuser", response)
  //     setGetEventNamed(response?.data?.data)
  //     //console.log(response?.data?.data, 'responsedata1234');
  //   } catch (error) {
  //   }
  // }

  // const getJoinEvents = async (item) => {
  //   //console.log("item", item)
  //   const data = item?.map(item => item.eventCode)

  //   //console.log("data123", data)
  //   const response = await getJoinGroup(data)
  //   //console.log("response256", response)

  //   setGetJoinEventName(response?.data?.data || [])
  // }

  const publicGroup = () => {
    navigate("/publicGroup");
  };

  const payment = () => {
    navigate("/subscription");
  };
  //----------------------Date filter ------------------//
  const onDate = (data) => {
    //console.log("fsdfgfdgdfgdfgdf", data);
    const dat = data.split("#");
    //console.log("hhh", dat);
    const dates = {
      startDate: dat[0],
      endDate: dat[1],
      search: "",
    };

    getEvent(dates);
    getPrivateGroup(dates);
  };
  const today = new Date().toISOString().split("T")[0];
  // startDate=2024-03-11&endDate=2024-03-11
  // ----------------End Date filter----------------------//
  // -----------------Delete MY events--------------------//
  const DleteMyEvents = async (item) => {
    try {
      //console.log("kkkkkkkkkkkkkkkkkkkk");
      const response = await deleteMyEvent(item);
      //console.log("Deleteresponse:", response);
      setEventProfile("");
      setEventName("");
      getEvent();
      getPrivateGroup();
      getProfile();
      setDeleteItem("");
      setSuccessMessage({
        message: "Album deleted successfully",
        status: true,
      });
      setTimeout(() => {
        setSuccessMessage({ message: "", status: null });
      }, 3000);
    } catch (err) {
      //console.log("Error in DleteMyEvents:", err);
      setErrorMessage({ message: "Failed to delete event", status: false });
      setTimeout(() => {
        setErrorMessage({ message: "", status: null });
      }, 3000);
    }
  };
  //---------------end delete event---------------------------//
  //----------------Edit My Event-----------------------------//

  //----------------------------------------------------------//

  const handleDeleteOpen = (item) => {
    setOpens(true);
    // DleteMyEvents
    setDeleteItem(item);
  };

  const handledeleteclose = () => {
    setDeleteItem("");
    setOpens(false);
  };

  const handleAgree = () => {
    DleteMyEvents(deleteItem);
    setOpens(false);
  };

  //-------------------------------------------------------------//
  //-----------------search onChange ----------------------------//

  // useEffect(() => {
  //   const data = {
  //     startDate: "",
  //     endDate: "",
  //     search: searchValue
  //   };
  //   getEvent(data);
  // }, [searchValue]);

  // const handleSearchChange = (data) => {
  //   //console.log("data", data);
  //   const urlPrefix = "http://localhost:3000";

  // // Check if `data` starts with `urlPrefix`, and if so, remove it
  // const processedData = data.startsWith(urlPrefix) ? data.replace(urlPrefix, "") : data;

  // setSearchValue(processedData);
  //   // setSearchValue(data);
  //   const datas = {
  //     startDate: "",
  //     endDate: "",
  //     search: processedData,

  //   };
  //   //console.log(data);

  //   getEvent(datas);
  // };

  const handleSearchChange = (data) => {
    const datas = {
      startDate: "",
      endDate: "",
      search: data,
    };

    getEvent(datas);
  };

  //------------------------------------------------------------//
  //---------------sticky scroll Header -----------------------//
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isSmallScreen = useMediaQuery("(max-width:750px)");

  const handleScroll = () => {
    setIsScrolling(true);
    let scrollTimeout;
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 200);
  };
  //-----------------------------------------------------------------------//

  //console.log("area", area);
  const handleLocationChange = (event) => {
    const selectedValue = event.target.value;
    const selectedLocation = locations.find(
      (location) => location.value === selectedValue
    );
    setArea(selectedLocation?.display || "");
    setCityName(selectedValue);
    setError("");
    setErrorMessage("");
  };

  const locations = [
    { display: "Select", value: "Select" },
    { display: "Bengaluru, India", value: "Bengaluru, India" },
    { display: "Canada", value: "Canada" },
    { display: "Cochin, India", value: "Cochin, India" },
    { display: "Europe", value: "Europe" },
    { display: "Europe - Amsterdam", value: "Amsterdam, Netherlands" },
    { display: "Europe - Bad Antogast", value: "Bad Antogast, Germany" },
    { display: "Europe - French Riviera", value: "French Riviera, France" },
    { display: "New Delhi, India", value: "New Delhi, India" },
    {
      display: "Thiruvananthapuram, India",
      value: "Thiruvananthapuram, India",
    },
    { display: "USA - Bay Area", value: "Bay Area, USA" },
    { display: "USA - Boone", value: "Boone, USA" },
    { display: "USA - DC", value: "DC, USA" },
    { display: "USA - Los Angeles", value: "Los Angeles, USA" },
  ];

  const formatDate = (date) => {
    const today = new Date();
    const eventDate = new Date(date);

    const isToday = today.toDateString() === eventDate.toDateString();
    const isYesterday =
      today.getDate() - 1 === eventDate.getDate() &&
      today.getMonth() === eventDate.getMonth() &&
      today.getFullYear() === eventDate.getFullYear();

    const diffInTime = today.getTime() - eventDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

    const isThisMonth =
      today.getMonth() === eventDate.getMonth() &&
      today.getFullYear() === eventDate.getFullYear();

    const isThisYear = today.getFullYear() === eventDate.getFullYear();

    if (isToday) return "Today";
    if (isYesterday) return "Yesterday";
    if (diffInDays <= 7) return "Last 7 days";
    if (isThisMonth) return "This Month";
    if (isThisYear) return "This Year";

    return "Earlier";

  };

  const getPaginatedEvents = () => {
    const startIndex = (page - 1) * eventsPerPage;
    const endIndex = startIndex + eventsPerPage;
    return geteventName.slice(startIndex, endIndex);
  };

  const groupByDate = (events) => {
    return events.reduce((acc, event) => {
      const dateGroup = formatDate(event.eventDate);
      if (!acc[dateGroup]) {
        acc[dateGroup] = [];
      }
      acc[dateGroup].push(event);
      return acc;
    }, {});
  };

  const [groupedEvents, setGroupedEvents] = useState({});

  useEffect(() => {
  //   setGroupedEvents(groupByDate(geteventName));
  // }, [geteventName]);
  setGroupedEvents(groupByDate(getPaginatedEvents()));
}, [geteventName, page]);

  console.log('group event 1234', groupedEvents);

  const renderEvents = () => {
    if (loadss) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh", // Ensure the height covers the main content area
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      );
    }

    // Check if there are any events in groupedEvents
    const hasEvents = Object.keys(groupedEvents).length > 0;

    if (!hasEvents) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            width: "100%",
            paddingTop: "45px",
          }}
        >
          <div
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
          >
            <Typography variant="h6" color="textSecondary">
              No Results Found
            </Typography>
          </div>
        </div>
      );
    }

    return Object.entries(groupedEvents).map(([date, events], dateIndex) => (
      <React.Fragment key={dateIndex}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              paddingLeft: "35px",
              textAlign: "center",
              fontWeight: "bold",
              display: "flex",
              width: "100px",
            }}
          >
            {date}
          </Typography>
        </Grid>
        {events.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <ThemeProvider theme={theme}>
              <Stack
                direction="column"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <div
                  style={{
                    paddingTop: "5px",
                    height: "100%",
                    overflow: "hidden",
                    paddingBottom: "40px",
                    width: "100%", // Ensure the inner content doesn't exceed its container
                  }}
                >
                  <MyCardEvent
                    item={item}
                    onCardClick={UserList}
                    defaultImage={defaultImage}
                    editEvent={editEvent}
                    handleDeleteOpen={handleDeleteOpen}
                    key={item.eventCode}
                    index={index}
                    copiedMap={copiedMap}
                    handleCopylink={handleCopylink}
                    albumCopy={handleAlbumLink}
                    albumCopied={copiedLink}
                    useDefaultImage={useDefaultImage}
                  />
                </div>
              </Stack>
            </ThemeProvider>
          </Grid>
        ))}
      </React.Fragment>
    ));
  };

  return (
    <div>
      {load ? <Loader open={load} /> : null}
      <DeleteModal
        open={opens}
        handleClose={handledeleteclose}
        handleAgrreDelete={handleAgree}
      />

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: handleClose }}
      >
        <Box
          sx={{
            ...style,
            width: isMobile ? "90%" : isTablet ? "70%" : 400,
            maxHeight: "80vh",
            overflowY: "auto",
            overflowX: "hidden",
            padding: isMobile ? 2 : 3, // Add padding for mobile view
          }}
        >
          <Stack style={{ position: "absolute", right: 10, top: 10 }}>
            <Box onClick={handleClose}>
              <Clear />
            </Box>
          </Stack>
          <Stack
            style={{
              display: "flex",
              zIndex: 1,
              position: "sticky",
              top: 0,
              padding: "8px 0",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loads && <CircularProgress color="inherit" />}
          </Stack>

          {errorMessage && (
            <Stack
              style={{
                display: "flex",
                zIndex: 1,
                position: "sticky",
                top: 0,
                padding: "8px 0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Alert severity={status === 200 ? "success" : "error"}>
                {errorMessage}
              </Alert>
            </Stack>
          )}

          <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Album Type{" "}
            </Typography>
            <Select value={group} onChange={handleChange}>
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"Public"}>Public</MenuItem>
              <MenuItem value={"Private"}>Private</MenuItem>
            </Select>
            {error === "Album type is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          {/* <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Album Name{" "}
            </Typography>
            <TextField
              label="Album Name"
              value={albumName}
              onChange={(e) => setAlbumName(e.target.value)}
            />
            {error === "Album name is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack> */}

          <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Album Name{" "}
            </Typography>
            <TextField
              label="Album Name"
              value={albumName}
              onChange={handleAlbumNameChange}
              error={Boolean(albumNameError)} // Show error state if there's an error
              helperText={albumNameError || `${albumName?.length}/25`} // Show error or character count
            />
            {error === "Album name is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Venue{" "}
            </Typography>
            {isOtherVenue ? (
              // Show TextField if 'Other' is selected
              <TextField
                placeholder="Type venue name"
                value={venue}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setVenue(inputValue);
                  setError("");
                  setErrorMessage("");

                  // If the user clears the TextField, switch back to dropdown
                  if (inputValue.trim() === "") {
                    setIsOtherVenue(false);
                    setVenue("Select");
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value.trim() === "") {
                    setIsOtherVenue(false); // Return to dropdown on blur if empty
                  }
                }}
                style={{ width: "100%" }}
              />
            ) : (
              // Show Select dropdown for predefined options
              <Select
                value={venue}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  // setVenue(selectedValue);
                  // setError("");
                  // setErrorMessage("");

                  if (selectedValue === "Other") {
                    setIsOtherVenue(true); // Switch to text field
                    setVenue(""); // Clear the venue for manual input
                  } else {
                    setVenue(selectedValue);
                    setIsOtherVenue(false); // Stay with dropdown
                     
                  }
                  setError("");
                  setErrorMessage("");
                }}
              >
                <MenuItem value={"Select"}>Select</MenuItem>
                <MenuItem value={"Secretariat Office A1"}>
                  Secretariat Office A1
                </MenuItem>
                <MenuItem value={"Special Enclosure - Shiva Temple"}>
                  Special Enclosure - Shiva Temple
                </MenuItem>
                <MenuItem value={"Yoga School"}>Yoga School</MenuItem>
                <MenuItem value={"Shiva Temple"}>Shiva Temple</MenuItem>
                <MenuItem value={"Satsang Backstage"}>
                  Satsang Backstage
                </MenuItem>
                <MenuItem value={"Gurukul"}>Gurukul</MenuItem>
                <MenuItem value={"Dhyan Mandir"}>Dhyan Mandir</MenuItem>
                <MenuItem value={"Visakha Laxmi Mantap - At day"}>
                  Visakha Laxmi Mantap - At day
                </MenuItem>
                <MenuItem value={"Visakha Laxmi Mantap - At night"}>
                  Visakha Laxmi Mantap - At night
                </MenuItem>
                <MenuItem value={"Radha Kunjh"}>Radha Kunjh</MenuItem>
                <MenuItem value={"Special Meet"}>Special Meet</MenuItem>
                <MenuItem value={"Guru Padukavanam"}>Guru Padukavanam</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            )}
            {error === "Venue is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
  <Typography style={{ fontSize: "18px", color: "black" }}>
    Venue Category{" "}
  </Typography>
  {isOtherVenueCategory ? (
    <TextField
      placeholder="Type venue category"
      value={venueAl}
      onChange={(e) => {
        const inputValue = e.target.value;
        setVenueAl(inputValue);
        setError("");
        setErrorMessage("");

        // Return to dropdown if input is cleared

        if (inputValue.trim() === "") {
          setIsOtherVenueCategory(false);
          setVenueAl("Select");
        }
      }}
      onBlur={(e) => {
        if (e.target.value.trim() === "") {
          setIsOtherVenueCategory(false);
        }
      }}
      style={{ width: "100%" }}
    />
  ) : (
    <Select
      value={venueAl}
      onChange={(e) => {
        const selectedValue = e.target.value;
        setVenueAl(selectedValue);
        setError("");
        setErrorMessage("");

        if (selectedValue === "Other") {
          setIsOtherVenueCategory(true);
          setVenueAl(""); // Clear previous value for manual input
        } else {
          setIsOtherVenueCategory(false);
        }
        setError("");
        setErrorMessage("");
      }}
    >
      <MenuItem value={"Select"}>Select</MenuItem>
      <MenuItem value={"Darshan Line"}>Darshan Line</MenuItem>
      <MenuItem value={"Wedding Event"}>Wedding Event</MenuItem>
      <MenuItem value={"Other"}>Other</MenuItem>
    </Select>
  )}
  {error === "Venue Category is required" && (
    <Typography
      style={{ color: "red", fontSize: "16px", textAlign: "left" }}
    >
      {error}
    </Typography>
  )}
</Stack>


          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Slot
            </Typography>
            <Select value={slot} onChange={handleSlotChange}>
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"Morning"}>Morning</MenuItem>
              <MenuItem value={"Evening"}>Evening</MenuItem>
              <MenuItem value={"Night"}>Night</MenuItem>
            </Select>
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
    <Typography style={{ fontSize: "18px", color: "black" }}>
      Place
    </Typography>
    {isOtherPlace ? (
      <TextField
        placeholder="Type place name"
        value={cityName}
        onChange={(e) => {
          const inputValue = e.target.value.trim();
          setCityName(inputValue);
          setError("");
          setErrorMessage("");

          // If the user clears the TextField, switch back to dropdown
          if (inputValue === "") {
            setIsOtherPlace(false);
            setCityName("Select"); // Reset to default dropdown value
          }
        }}
        onBlur={(e) => {
          if (e.target.value.trim() === "") {
            setIsOtherPlace(false);
            setCityName("Select"); // Reset to default dropdown value
          }
        }}
      />
    ) : (
      <Select
        value={cityName}
        onChange={(e) => {
          const selectedValue = e.target.value;
          if (selectedValue === "Other") {
            setIsOtherPlace(true);
            setCityName("");
          } else {
            setCityName(selectedValue);
            setIsOtherPlace(false);
          }
          setError("");
          setErrorMessage("");
        }}
      >
        {locations.map((location, index) => (
          <MenuItem key={index} value={location.value}>
            {location.display}
          </MenuItem>
        ))}
        <MenuItem value={"Other"}>Other</MenuItem>
      </Select>
    )}
  </Stack>


          <Stack spacing={1}>
            <Typography
              style={{ fontSize: "18px", color: "black", textAlign: "left" }}
            >
              Event Date{" "}
            </Typography>
            <TextField
              placeholder="Select Event Date"
              type="date"
              value={eventDate}
              onChange={(e) => {
                //console.log(e.target.value);
                setEventDate(e.target.value);
                setError("");
                setErrorMessage("");
              }}
              style={{ width: "100%" }}
              inputProps={{ max: today }}
            />
            {error === "Event Date is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Button
            variant="contained"
            fullWidth
            style={{ marginTop: 20 }}
            onClick={handleEventSubmit}
          >
            {isEditData ? "Update" : "Create"}
          </Button>
        </Box>
      </Modal>

      <div
        style={{
          position: "sticky",
          top: "60px",
          zIndex: "999",
          width: "100%",
          // transition: "top 0.3s",
          // top: isScrolling ? "-100px" : "70px",
        }}
      >
        <div>
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              backgroundColor: "#F4FAFF",
              // border: "1px solid #D4D4D4",
            }}
            elevation={0}
          >
            {isMobileOrTablet ? (
              <Grid container spacing={2} sx={{ padding: "10px" }}>
                <Grid item xs={12} sm={12}></Grid>
                <Grid item xs={12} sm={12}>
                  <RangeDatePicker width={"100%"} filterDate={onDate} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    height="20px"
                    fullWidth
                    variant="text"
                    onClick={handleOpenForCreate}
                    style={{
                      textTransform: "none",
                      fontWeight: "600",
                      backgroundColor: "white",
                      border: "1px solid #D4D4D4",
                    }}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      src={createAlbumICon}
                      alt=""
                      srcset=""
                    />
                    Create Album
                  </Button>
                  <IconButton aria-label="filter">
                    {/* <FilterListIcon /> */}
                  </IconButton>
                  <SearchField
                    width={"400px"}
                    searchProps={handleSearchChange}
                    placeholder=""
                  />
                  {/* <TextField onChange={handleChange} /> */}
                </Grid>
              </Grid>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",

                  justifyContent: "space-between",
                  padding: 5,
                  // width: "100%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <Grid>
                    <Button
                      height="20px"
                      fullWidth
                      variant="text"
                      onClick={handleOpenForCreate}
                      style={{
                        textTransform: "none",
                        fontWeight: "600",
                        backgroundColor: "white",
                        border: "1px solid #D4D4D4",
                      }}
                    >
                      <img
                        style={{ marginRight: "10px" }}
                        src={createAlbumICon}
                        alt=""
                        srcset=""
                      />
                      Create Album
                    </Button>
                  </Grid>
                </div>
                <div>
                  <Stack
                    direction={"row"}
                    gap={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      padding: 10,
                    }}
                  >
                    {" "}
                    <RangeDatePicker width={"100%"} filterDate={onDate} />
                    <SearchField
                      width="400px"
                      searchProps={handleSearchChange}
                      // placeholder="Search, Album name"
                      style={{ backgroundColor: "white" }}
                    />
                  </Stack>
                </div>
              </div>
            )}
          </Card>
          {/* <h2>sdfghj</h2> */}
          {SuccessMessage.status && (
            <Alert
              severity={SuccessMessage.status ? "success" : "error"}
              style={{ marginBottom: 10 }}
            >
              {SuccessMessage.message}
            </Alert>
          )}
        </div>
      </div>
      <Stack
        marginTop="60px"
        marginLeft={2}
        spacing={2}
        alignItems={isMobile ? "flex-start" : "center"}
      >
        {/* First Section */}
        <Stack
          direction="column"
          alignItems={isMobile ? "flex-start" : "center"}
        >
          {/* <PhotoAlbumIcon fontSize="large" /> */}
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#3974CB",
              mt: 2,
            }}
          >
            My Uploads
          </Typography>
        </Stack>

        {/* Second Section */}
        <Typography
          variant="body1"
          sx={{
            fontSize: isSmallScreen ? "1em" : "0.em",
            textAlign: isSmallScreen ? "left" : "center", // Corrected textAlign
            mt: 5,
            mb: 7,
            maxWidth: 800,
            paddingLeft: isSmallScreen ? "" : 0, // Adjusts padding on small screens
          }}
        >
          Albums created by you are displayed below.
        </Typography>

        {/* Button Section */}
        <Grid
          container
          justifyContent={isMobile ? "flex-start" : "center"} // Centers button on mobile
        >
          <Button
            height="20px"
            // fullWidth
            variant="contained"
            onClick={handleOpenForCreate}
            style={{
              // alignItems: "center",
              // justifyContent: "center",
              textTransform: "none",
              fontWeight: "600",
              color: "white",
              backgroundColor: "#2167CF",
            }}
          >
            <img
              style={{ marginRight: "10px" }}
              src={createAlbumIConwhite}
              alt=""
              srcSet=""
            />
            Create Album
          </Button>
        </Grid>
      </Stack>

      <div style={{ marginTop: "10px", position: "relative" }}>
        {loadss ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <Box
            maxWidth="1400px"
            margin="0 auto"
            padding={isMobileOrTablet ? 1 : 2}
          >
            <Grid container spacing={2} marginTop={isMobileOrTablet ? 1 : null}>
              {renderEvents()}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '20px 0',
                marginTop: '20px'
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size={isMobileOrTablet ? "small" : "medium"}
              />
            </Box>

          </Box>
        )}
      </div>

      {/* <div>{renderEvents(groupedEvents)}</div> */}
    </div>
  );
}
