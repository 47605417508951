import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Avatar,
  Backdrop,
  Card,
  CircularProgress,
  InputLabel,
  Pagination,
  Stack,
  setRef,
  FormHelperText,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Snackbar,
} from "@mui/material";
import useDrivePicker from "react-google-drive-picker";
import {
  DownloadImage,
  deleteImageApi,
  fileCheking,
  getAllImages,
  faceDetectedImagesApi,
  uploadImage,
  getProfileApi,
  getDetectedHighImages,
  getDetectedLowImages,
  UploadDetedImages,
  GetUploadDectedImages,
  UploadProgress,
} from "../API/Photographer";
import { getaddedUser } from "../API/Auth";
import { Grid } from "@mui/material";
// import LazyLoadedImage from './component/lazyLoadImage';
import { useLocation, useNavigate } from "react-router";
import noImage from "../images/noiamge.jpg";
import "../css/Drive.css";
import SubscriptionPay from "./payment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "../css/Gallary.css";
import DeleteImages from "./deleteImage";
import ViewImages from "./viewImages";
import SuccessProgress from "./Component/successProgress";

import { Clear, PersonAdd, Upload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateUserImage } from "../Store/action";
import { styled } from "@mui/material/styles";
// import CircularProgress, {
//   circularProgressClasses,
// } from '@mui/material/CircularProgress';
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Profile from "./Header/Profile/profile";
import albummm from "../../src/images/photoalbum-icon.svg";
import locaTIIIION from "../../src/images/location-2-icon.svg";
import timmmmm from "../../src/images/date-time-slot-2-icon.svg";
import gallery from "../../src/images/create-album-icon.svg";
import { CloseCircleTwoTone } from "@ant-design/icons";
import Avrcard from "./Component/Avrcard";
import AvatarDetectLoader from "./Component/AvatarDetectLoader";
import AvatarLoader from "./Component/AvatarLoader";

export default function PhotographersUploadImages() {
  const [open, setOpen] = useState(false);
  const navigator = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [uploadPercentage, setuploadpercentage] = useState("");
  //   const [selectedAvatar, setSelectedAvatar] = useState(); // Add this line

  const [selectedUrl, setSelectedUrl] = useState([]);
  const [deleteOpen, setdeleteOpen] = useState(false);
  const [deleteData, setdeleteData] = useState("");
  const [ownPhoto, setOwnPhoto] = useState([]);
  const [myPhotos, setMyPhotos] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [detectedCount, setDetectedCount] = useState(0);
  const [renderKey, setRenderKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [ownPhotoExist, setOwnPhotoExist] = useState("");
  const [status, setStatus] = useState();
  const [finalResponseData, setfinalResponseData] = useState("");
  const [highImageData, setHighImagesData] = useState("");
  const [isDetctedLoder, setisDetctedLoder] = useState(false);
  const [detectedImages, setDetectedImages] = useState([]);
  const [finaldetectedImages, setFinaldetectedImages] = useState([]);
  const [finalImagesLoader, setfinalImagesLoader] = useState(false);
  const location = useLocation();
  const fetchImagesRef = useRef(false);
  const [selectedImages, setSelectedImages] = useState([]); // Track selected images
  const [successMessage, setSuccessMessage] = useState(false); // State for success message (Snackbar)
  const [deleteMultipleMode, setDeleteMultipleMode] = useState(false); // Toggle delete mode

  const handleSelectImage = (imageId, event) => {
    event.stopPropagation(); // Prevent triggering the image preview when clicking the checkbox

    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.includes(imageId)
        ? prevSelectedImages.filter((id) => id !== imageId)
        : [...prevSelectedImages, imageId]
    );
  };



  const isImageSelected = (imageId) => selectedImages.includes(imageId);
  const handleDeleteSelectedImages = async () => {
    try {
      // Send delete requests for selected images
      const deletePromises = selectedImages.map(async (imageId) => {
        const data = {
          item: { _id: imageId },
        };
        return await deleteImageApi(data); // Call your delete API
      });

      // Wait for all delete requests to finish
      await Promise.all(deletePromises);

      // Remove the deleted images from both ownPhoto and selectedUrl state
      setOwnPhoto((prevImages) => prevImages.filter((image) => !selectedImages.includes(image._id)));
      setSelectedUrl((prevImages) => prevImages.filter((image) => !selectedImages.includes(image._id)));

      // Clear the selected images
      setSelectedImages([]);
      setSuccessMessage(true); // Show success message
    } catch (error) {
      console.error("Error deleting images:", error);
    }
  };


  const eventData = location.state?.eventProps;
  //   //console.log("eventDAtaaa", eventData);
  // const imageUrl = location.state?.imageUrl;
  const [uploadProgress, setUploadProgress] = useState(0);

  const [userData, setUserData] = useState({
    getUser: [],
    userProfile: {},
  });

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false); 

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleAgreeDelete = async () => {
    await handleDeleteSelectedImages();
    setOpenConfirmDelete(false);
    setSuccessMessage(true);
  };

  const handleCloseSuccessMessage = () => {
    setSuccessMessage(false);
  };

  const handleDeleteSelectedImagesClick = () => {
    if (selectedImages.length > 0) {
      handleConfirmDelete(); 
    }
  };

  const dispatch = useDispatch();
  const eventTab = location?.state;
  const eventTabs = navigator?.state?.eventTab;

  const profileData = useSelector((state) => state.user);

  const userId = profileData?.user?.UserId;
  //   useEffect(() => {
  //     getAllAddedUser();
  //   }, []);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  ////console.log("eventData",eventData)
  const handleOpen = () => setOpen(true);

  const [value, setValue] = useState(0);
  const [values, setValues] = useState(0);
  const [loader, setloader] = useState(false);

  const [loaderScroll, setloaderScroll] = useState(false);
  const [loaderForModel, setloderForModel] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobileOrTablet ? 300 : 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  //   //console.log(values, "valuesssss");
  //   //console.log(detectedImages, "valuesdetectedImagesssss");
  //   //console.log(ownPhoto, "ownPhoto");

  //----------------Google Drive picker------------------//
  const [openPicker, authResponse] = useDrivePicker();
  // const [authToken, setAuthToken] = useState(authResponse?.access_token);
  let authToken = authResponse?.access_token;
  localStorage.setItem("ggToken", authResponse?.access_token);

  const subscriptionMessage = () => { };

  useEffect(() => {
    authToken = authResponse?.access_token;
  }, [authResponse]);

  const handleOpenPicker = () => {
    const pickerCallback = (data) => {
      if (data.action === "cancel") {
      } else if (data.action === "picked") {
        handleDriveFileSelection(data); // Assuming data.docs contains selected files
      }
    };

    openPicker({
      clientId:
        "174146068432-ovvbm90df3r06nrq7osdq7v2akjnio9c.apps.googleusercontent.com",
      developerKey: "AIzaSyASL3yMev0JmvprcVv_VXnHFV2oy8k5MGE",
      viewId: "DOCS_IMAGES_AND_VIDEOS",
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: true,
      callbackFunction: pickerCallback,
    });
  };

  const handleDriveFileSelection = async (data) => {
    setErrorMessage("");

    if (!localStorage.getItem("ggToken")) {
      return;
    }

    const actualFiles = await Promise.all(
      data.docs.map(async (file) => {
        const response = await fetch(
          `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,
          {
            headers: new Headers({
              Authorization: "Bearer " + localStorage.getItem("ggToken"),
            }),
          }
        );
        const blob = await response.blob();
        const newFile = new File([blob], file.name, { type: blob.type });
        return newFile;
      })
    );

    setSelectedFile(actualFiles);
    localStorage.removeItem("ggToken");
  };

  //-----------------------------------------------------//
  //Loading

  const ITEMS_PER_PAGE = 40;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  //----------------------Image scrol---------------------//

  const getUserType = () => {
    return localStorage.getItem("userType") || "user";
  };

  const userType = getUserType();
  //   //console.log("UserType:", userType);

  const getMyphotoExist = async (data) => {
    try {
      const currentPages = 1;
      const status = "myPhotos";
      const response = await getAllImages(
        status,
        currentPages,
        eventData?.eventCode
      );
      //console.log("response123456", response);
      setOwnPhotoExist(response?.data?.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  //   //console.log("eventData12345", eventData);

  //   const getUploadDetectedImageInAUth = async (datas) => {
  //     //console.log("Testtttt");

  //     try {
  //       setfinalImagesLoader(true)
  //       const data = {
  //         userId: userId,
  //         albumId: location?.state?.eventProps?._id,
  //         page: '1'
  //       };
  //       const response = await GetUploadDectedImages(data);
  //       //console.log("GetUploadDectedImages", response);

  //       //console.log("GetUploadDectedImages1", response?.data);
  //       setFinaldetectedImages(response?.data?.data);
  //       setDetectedCount(response?.data?.totalCount || 0); // Set the totalCount

  //       //console.log("GetUploadDectedImages", response)

  //       //console.log("GetUploadDectedImages1", response?.data)
  //       setFinaldetectedImages(response?.data?.data)
  //       setDetectedCount(response?.data?.totalCount || 0); // Set the totalCount

  //       setfinalImagesLoader(false);
  //     } catch (error) {
  //       //console.log("error", error)
  //     }
  //   };

  const getImages = async (data) => {
    // //console.log("GetImages");

    try {
      let currentPages = currentPage;
      if (data !== undefined) {
        currentPages = data;
      }

      setloader(true);

      const status = values === 0 ? "allPhotos" : "myPhotos";
      const response = await getAllImages(
        status,
        currentPages,
        eventData?.eventCode
      );

      //console.log("responnnseee12345", response);
      setfinalResponseData(response);

      if (
        response?.data?.Pages.tillImagesget === response?.data?.data?.totalCount
      ) {
        setloader(false);
        setloaderScroll(false);
      }

      const newImages = response?.data?.data?.uploads;

      if (newImages?.length === 0) {
        setloader(false);
      }

      setCurrentPage(response?.data?.Pages.currentPage);
      setTotalPage(response?.data?.Pages.totalPage);

      // Set counts based on the status
      if (status === "allPhotos") {
        setTotalCount(response?.data?.data?.totalCount || 0);
      } else if (status === "myPhotos") {
        setUploadedCount(response?.data?.data?.totalCount || 0);
      }

      const updatedImages = newImages?.map((image) => ({
        ...image,
        currentPage: response?.data?.data?.currentPage,
      }));
      //console.log("un sorted phtots", updatedImages);

      const sortedSelectedImages = updatedImages.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      //console.log("sorted phtots", sortedSelectedImages);

      if (status === "myPhotos") {
        setOwnPhoto((prevData) => {
          const uniqueNewImages = sortedSelectedImages?.filter(
            (newImage) =>
              !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
          );
          return [...prevData, ...uniqueNewImages];
        });

        console.log("ownPhotos123", ownPhoto);
      }

      setSelectedUrl((prevData) => {
        const uniqueNewImages = sortedSelectedImages?.filter(
          (newImage) =>
            !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
        );
        return [...prevData, ...uniqueNewImages];
      });
      //console.log("selectedUrl1234", selectedUrl);
      setloaderScroll(false);
      setloader(false);
    } catch (error) {
      console.error("Error fetching images:", error);
      setloader(false);
    }
  };

  // const getImages = async (data) => {
  //   try {
  //     let currentPages = currentPage;
  //     if (data !== undefined) {
  //       currentPages = data;
  //     }
  
  //     setloader(true);
  
  //     const status = values === 0 ? "allPhotos" : "myPhotos";
  //     const response = await getAllImages(
  //       status,
  //       currentPages,
  //       eventData?.eventCode
  //     );
  
  //     setfinalResponseData(response);
  
  //     if (
  //       response?.data?.Pages.tillImagesget === response?.data?.data?.totalCount
  //     ) {
  //       setloader(false);
  //       setloaderScroll(false);
  //     }
  
  //     const newImages = response?.data?.data?.uploads;
  
  //     if (newImages?.length === 0) {
  //       setloader(false);
  //     }
  
  //     setCurrentPage(response?.data?.Pages.currentPage);
  //     setTotalPage(response?.data?.Pages.totalPage);
  
  //     // Set counts based on the status
  //     if (status === "allPhotos") {
  //       setTotalCount(response?.data?.data?.totalCount || 0);
  //     } else if (status === "myPhotos") {
  //       setUploadedCount(response?.data?.data?.totalCount || 0);
  //     }
  
  //     // New folder-based integration
  //     const folderImageCount = response?.data?.Pages.folderImageCount || 0;
  //     const updatedImages = newImages?.map((image) => ({
  //       ...image,
  //       currentPage: response?.data?.Pages.currentPage,
  //       folderImageCount: folderImageCount, // Include folder-based counts
  //     }));
  
  //     const sortedSelectedImages = updatedImages.sort(
  //       (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  //     );
  
  //     if (status === "myPhotos") {
  //       setOwnPhoto((prevData) => {
  //         const uniqueNewImages = sortedSelectedImages?.filter(
  //           (newImage) =>
  //             !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
  //         );
  //         return [...prevData, ...uniqueNewImages];
  //       });
  //     }
  
  //     setSelectedUrl((prevData) => {
  //       const uniqueNewImages = sortedSelectedImages?.filter(
  //         (newImage) =>
  //           !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
  //       );
  //       return [...prevData, ...uniqueNewImages];
  //     });
  
  //     setloaderScroll(false);
  //     setloader(false);
  //   } catch (error) {
  //     console.error("Error fetching images:", error);
  //     setloader(false);
  //   }
  // };
  
  
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  // const handleScroll = () => {
  //   if (
  //     finalResponseData?.data?.Pages.tillImagesget <
  //     finalResponseData?.data?.data?.totalCount
  //   ) {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       setCurrentPage((prevPage) => prevPage + 1);
  //       //console.log("currentPage2", currentPage);
  //       // setLoad(true)
  //       setloaderScroll(true);
  //     }
  //   }
  // };

  // const debouncedHandleScroll = debounce(handleScroll, 300);

  // useEffect(() => {
  //   window.addEventListener("scroll", debouncedHandleScroll);
  //   return () => window.removeEventListener("scroll", debouncedHandleScroll);
  // }, [currentPage, totalPage, debouncedHandleScroll]);

  useEffect(() => {
    getImages();
  }, [currentPage, values, myPhotos, deleteOpen, open]);

  //   useEffect(() => {
  //     // detectesPhotos()
  //     // getMyphotoExist();
  //   }, []);
  //-----------------------------------------------------------------------//

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage("");
  };

  const handleChanges = (event, newValue) => {
    setValues(newValue);
    setCurrentPage("");
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile("");
    setFileExist("");
    setErrorMessage("");
    setuploadpercentage("");
    setStatus("");
    setloderForModel(false);
    // setFiles([])
    setTotalProgress(0);
  };

  //   //console.log(finalResponseData, "finalResponseData");

  const [fileExist, setFileExist] = useState([]);
  const fileCheck = async (data, selectedFiles) => {
    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      const res = await fileCheking(data, eventData?.eventCode, formData);
      setloderForModel(false);

      const fileExist = res.data.data;

      const newImageFileNames = fileExist
        ?.filter((item) => item.exists === "New image")
        .map((item) => item.fileName);

      const filteredSelectedFiles = selectedFiles?.filter((file) =>
        newImageFileNames?.includes(file.name)
      );

      setSelectedFile(filteredSelectedFiles);

      setFileExist(res.data.data);
    } catch (err) {
      //console.log(err);
    }
  };

  //   //console.log("fileExist", fileExist);
  // //console.log("fileExistfileExistfileExist",fileExist);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.async = true;
    script.onload = () => {
      ////console.log("Test", window.gapi); // Check if gapi is defined
    };
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  useEffect(() => {
    if (fetchImagesRef.current) {
      getImages();
      fetchImagesRef.current = false;
    }
  }, [fetchImagesRef.current]);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  function subscriptionStatus(data) {
    if (data === 200) {
      setStatus("");
      setErrorMessage("");
    }
  }

  //--------------------------Gallery View------------------//
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const openGallery = (url, name, page, fileName) => {
    // //console.log("url", url, name, page, fileName);

    const data = {
      Url: url,
      filename: name,
      currentPage: page,
      totalPage: totalPage,
      originalName: fileName,
    };

    // //console.log("Data", data);
    setdeleteOpen(true);
    setdeleteData(data);
    //  navigator('/ViewImages', { state: { data } })
  };

  const viewGallerys = (url, name, page, fileName, item) => {
    const data = {
      Url: url,
      filename: name,
      currentPage: page,
      totalPage: totalPage,
      originalName: fileName,
      item: item,
    };

    // //console.log("Data", data);
    setdeleteOpen(true);
    setdeleteData(data);
    // navigator('/DeleteImages', { state: { data } })
  };
  const closeDeleteImage = (data) => {
    //console.log("cloase", data);
    setdeleteOpen(data);
  };

  const closeGallery = () => {
    setSelectedImageIndex(null);
    setIsGalleryOpen(false);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => prevIndex - 1);
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => prevIndex + 1);
  };

  const galleryView = () => {
    return (
      <Modal
        open={isGalleryOpen}
        onClose={closeGallery}
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "black",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <IconButton onClick={goToPreviousImage}>
            <ChevronLeftIcon />
          </IconButton>
          <img src={selectedImageIndex} alt="Selected" />
          <IconButton onClick={goToNextImage}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      </Modal>
    );
  };

  //---------------------------Gallery End------------------//

  //   const [adduserLoad, setAddUserLoad] = useState(false);
  //   const getAllAddedUser = async () => {
  //     try {
  //       const response = await getaddedUser();
  //       setAddUserLoad(false);
  //       //console.log("response123", response?.data?.data?.AdditionalUsers[0]);
  //       setUserData({
  //         getUser: response?.data?.data?.AdditionalUsers || [],
  //         userProfile: response?.data?.data[0] || {},
  //       });
  //       setSelectedAvatar(response?.data?.data?.AdditionalUsers[0] || []);
  //     } catch (error) {
  //       //console.log("response error", error);
  //     }
  //   };

  const handleNavigate = (image) => {
    //console.log("image", image);
    const userImage = image?.imageUrl;
    const userId = image?._id;
    const data = {
      userImage,
      userId,
    };
    dispatch(updateUserImage(data));
    // navigator("/", { state: { imageUrl: image } });
  };

  const bytesToMB = (bytes) => {
    if (bytes === 0) return "0 MB";
    const mb = bytes / (1024 * 1024);
    return `${mb.toFixed(2)} MB`;
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 13,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: "linear-gradient(90deg, #e01cd5 0%, #1CB5E0 100%)",
    },
  }));

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  //   //console.log("values", values);

  const [startDetect, setStartDetect] = useState(false);

  const [files, setFiles] = useState([]);
  // const [uploadProgress, setUploadProgress] = useState({});
  const [openModal, setOpenModal] = useState(true);
  const [totalProgress, setTotalProgress] = useState(0);
  const dialogContentRef = useRef(null); // Create a ref for the dialog content

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
    setOpen(true);
    setOpenModal(true);
    setTotalProgress(0);
  };

  const handleScrollContainerStyle = {
    overflowX: "auto",
    whiteSpace: "nowrap",
  };

  const handleUpload = async () => {
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (files.length === 0) {
      alert("Please select at least one image");
      return;
    }

    setOpen(true);
    const batches = [];
    for (let i = 0; i < files.length; i += 1) {
      batches.push(files.slice(i, i + 1));
    }

    let totalUploaded = 0;

    for (const batch of batches) {
      await uploadBatch(batch);
      totalUploaded += batch.length;
      setTotalProgress((totalUploaded / files.length) * 100);
    }
    setOpenModal(false);
    getMyphotoExist();
  };

  const uploadBatch = async (batch) => {
    const formData = new FormData();
    batch.forEach((file) => formData.append("files", file));

    try {
      const userId = localStorage.getItem("userId");

      const data = {
        event: eventData?.eventCode,
        eventName: eventData?.name,
        eventType: eventData?.eventType,
        email: eventData?.createdByEmail,
        eventCreatedBy: eventData.createdBy,
        userId,
      };

      formData.append("data", JSON.stringify(data));
      const response = await uploadImage(formData, setUploadProgress);

      const uploadId = response?.data?.uploadId;
      await Promise.all(batch.map((file) => trackProgress(uploadId, file)));
    } catch (error) {
      console.error("Error during upload:", error);
    }
  };

  const trackProgress = async (uploadId, file) => {
    let progress = 0;
    while (progress < 100) {
      try {
        const uploadResponse = await UploadProgress(uploadId);
        progress = uploadResponse?.data?.progress;
        //console.log(`Progress for ${file.name}: ${progress}%`); // Debug log

        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: progress === -1 ? 100 : progress,
        }));

        if (progress === 100 || progress === -1) {
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
      } catch (error) {
        console.error(`Error during upload progress for ${file.name}:`, error);
        break;
      }
    }
  };

  const handleNextPage = debounce(() => {
    if (currentPage < totalPage) {
      getImages(currentPage + 1); 
    }
  }, 500); 
  
  const handlePreviousPage = debounce(() => {
    if (currentPage > 1) {
      getImages(currentPage - 1); 
    }
  }, 300);

  return (
    <div key={renderKey} style={{
      overflowX: "hidden"
    }}>
      <div style={{ zIndex: 10 }}>
        {deleteOpen && values === 1 && (
          <DeleteImages
            deleteData={deleteData}
            eventData={eventData}
            closeDeleteImage={closeDeleteImage}
          />
        )}

        {
          // deleteOpen &&  values===1 && <ViewImages deleteData={deleteData} closeDeleteImage={closeDeleteImage}/>
        }

        {deleteOpen && values === 0 && (
          <ViewImages
            deleteData={deleteData}
            eventData={eventData}
            closeDeleteImage={closeDeleteImage}
          />
        )}
      </div>

      {/* {galleryView()} */}

      <div>
        <Box
          style={{
            padding: isMobile ? 5 : 10,
            maxWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            backgroundColor: "#F4FAFF",
            marginTop: isMobile ? 20 : 70,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            style={{
              marginTop: isMobile ? 50 : isTablet ? 0 : 10,
            }}
            spacing={isMobile ? 1 : 2}
          >
            <img
              src={albummm}
              alt="Gallery Icon"
              style={{ width: 18, height: 18, color: "#616161" }}
            />
            <Typography
              style={{
                fontSize: isMobile ? 14 : 16,
                color: "#616161",
                whiteSpace: "nowrap",
              }}
            >
              Album Details
            </Typography>
          </Stack>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              flexDirection: isMobile ? "column" : "row",
              marginTop: isMobile ? 10 : 0,
            }}
          >
            <Stack
              direction={isMobile ? "column" : isTablet ? "column " : "row"}
              alignItems={
                isMobile ? "flex-start" : isTablet ? "flex-start" : "center"
              }
              spacing={isMobile ? 1 : isTablet ? 2 : 2}
            >
              <Typography
                style={{
                  fontSize: isMobile ? 16 : 18,
                  fontWeight: "bold",
                  color: "#1565C0",
                  whiteSpace: "nowrap",
                  marginTop: isTablet ? 5 : 0,
                }}
              >
                {eventData?.name} | {eventData?.venueAlias}
                
              </Typography>
              <Typography
                style={{
                  fontSize: isMobile ? 12 : 14,
                  color: "#616161",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                  marginTop: isTablet ? 5 : 0,
                }}
              >
                <img
                  src={locaTIIIION}
                  alt="Location Icon"
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                {eventData?.venueAlias}
              </Typography>
              <Typography
                style={{
                  fontSize: isMobile ? 12 : 14,
                  color: "#616161",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                  marginTop: isTablet ? 10 : 0,
                }}
              >
                <img
                  src={timmmmm}
                  alt="Time Icon"
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                {eventData?.eventDate &&
                  new Date(eventData?.eventDate).toLocaleDateString(
                    "en-GB"
                  )}{" "}
                | {eventData?.slot}
              </Typography>
            </Stack>
          </Box>
        </Box>

        {/* changes UI END */}
        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'fixed', width: '100%', zIndex: 1, backgroundColor: 'white' }}> */}
        {/* <Typography style={{ textAlign: 'left', marginLeft: 10, fontSize: 30 }}>{eventData?.name}</Typography> */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <div style={handleScrollContainerStyle}>
            <Tabs
              value={values}
              onChange={handleChanges}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {userType === "photographer" && [
                <Tab
                  key="all-photos"
                  label={
                    <span>
                      All photos{" "}
                      <span
                        style={{
                          backgroundColor: values === 0 ? "#1266F1" : "",
                          color: values === 0 ? "white" : "grey",
                          borderRadius: "5px",
                          padding: "2px 6px",
                        }}
                      >
                        {totalCount || 0}
                      </span>
                    </span>
                  }
                  sx={{ marginRight: 1, textTransform: "none", fontSize: 18 }}
                />,
                <Tab
                  key="uploaded-photos"
                  label={
                    <span>
                      Photos uploaded by me{" "}
                      <span
                        style={{
                          backgroundColor: values === 1 ? "#1266F1" : "",
                          color: values === 1 ? "white" : "grey",
                          borderRadius: "5px",
                          padding: "2px 6px",
                        }}
                      >
                        {uploadedCount || 0}
                      </span>
                    </span>
                  }
                  sx={{ marginLeft: 2, textTransform: "none", fontSize: 18 }}
                />,
              ]}
            </Tabs>
        <div>
        <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
         marginTop:2,
         marginLeft: isMobileOrTablet ? 0 : 70,
    }}>
            <Button onClick={handlePreviousPage} disabled={currentPage === 1 || loader}>
                Previous
            </Button>
            <span>Page {currentPage} of {totalPage}</span>
            <Button onClick={handleNextPage} disabled={currentPage === totalPage || loader}>
                Next
            </Button>
        </Box>
        </div>
          </div>
        
          <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ borderRadius: 5, zIndex: 500 }}
      >
        <Box sx={{ width: "100%", p: 4, borderRadius: 2 }}>
          {/* Modal Content */}
        </Box>
      </Modal>

          <Box>
            {(values === 0 || values === 1) && userType === "photographer" && (
              <Button
                disableElevation
                onClick={handleOpen}
                variant="contained"
                style={{
                  height: 40,
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#1E88E5",
                  display: "flex",
                  alignItems: "center",
                  marginTop: isMobile ? -55 : -60,
                  marginRight: isMobile ? 0 : 30,
                  position: "fixed",
                  right: 0,
                  zIndex: 1,
                }}
                size="large"
              >
                <img
                  src={gallery}
                  alt="Gallery Icon"
                  style={{ width: 18, height: 18, marginRight: 8 }}
                />
                Upload Images
              </Button>


            )}
            {(values === 1) && userType === "photographer" &&
              <Button
                variant="contained"
                onClick={() => setDeleteMultipleMode(!deleteMultipleMode)} 
                sx={{
                  marginTop: 2,
                  marginRight: 4,  // Add margin right
                  padding: '8px 16px',
                  backgroundColor: "#1976d2",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1976d2",
                  },
                }}
              >
                {deleteMultipleMode ? "Cancel Delete" : "Delete Multiple Photos"}
              </Button>
            }

          </Box>
        </div>
      </div>

      <Box style={{ zIndex: 0 }}>
        <TabPanel value={values} index={0}>
          {selectedUrl?.length === 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "200px",
                  border: "2px solid #337AB7",
                  borderRadius: "8px",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "60%",
                  margin: "0 auto",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    color: "#333",
                    margin: 0,
                  }}
                >
                  No photos were found please upload new photos in the album.
                </p>
              </div>
            </div>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                  minHeight: "100vh",
                  gap: 0.05,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    minHeight: "100vh",
                    gap: 0.05,
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: isMobileOrTablet
                        ? "repeat(2, 1fr)"
                        : "repeat(4, 1fr)",
                      gap: "7px",
                    }}
                  >
                    {/* Loop through selectedUrl to display images */}
                    {selectedUrl?.slice(
                      (currentPage - 1) * ITEMS_PER_PAGE,
                      currentPage * ITEMS_PER_PAGE
                    )?.map((item, index) => (
                      <Box
                        key={item._id}
                        sx={{
                          position: "relative",
                          cursor: "pointer",
                          borderRadius: 2,
                          overflow: "hidden",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {/* Checkbox for selecting images */}


                        <span
                          style={{
                            position: "absolute",
                            top: 5,
                            left: 5,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            padding: "2px 5px",
                            borderRadius: "3px",
                            zIndex: 10,
                            fontSize: "14px",
                          }}
                        >
                          {index + 1 + (currentPage - 1) * ITEMS_PER_PAGE}
                        </span>

                        {/* Image preview */}
                        <img
                          src={item?.Images?.lowResolution}
                          alt={`Image ${index + 1}`}
                          style={{
                            width: "100%",
                            // height: "200px",
                            objectFit: "cover",
                            borderRadius: 8,
                          }}
                        />
                      </Box>
                    ))}
                  </Box>



                </Box>

              </Box>
              {/* {selectedUrl.length > 0 && ( // Check if there are photos to paginate */}
              <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                {/* <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  variant="outlined"
                  shape="rounded"
                /> */}
                <Box>
                  <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                  </Button>
                  <span>Page {currentPage} of {totalPage}</span>
                  <Button onClick={handleNextPage} disabled={currentPage === totalPage}>
                    Next
                  </Button>
                </Box>
              </Box>
              {/* )} */}
              {loaderScroll && (
                <div
                  style={{
                    zIndex: 9999,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              )}
            </>
          )}
        </TabPanel>
        {userType === "photographer" && (
          <TabPanel value={values} index={1}>
            {ownPhoto?.length === 0 ? (
              
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "200px",
                    border: "2px solid #337AB7",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "60%",
                    margin: "0 auto",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      color: "#333",
                      margin: 0,
                    }}
                  >
                    No photos were found please upload new photos in the album.
                  </p>
                </div>
              </div>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    minHeight: "100vh",
                    gap: 0.05,
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: isMobileOrTablet
                        ? "repeat(2, 1fr)"
                        : "repeat(4, 1fr)",
                      gap: "7px",
                    }}
                  >
                    {ownPhoto?.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)?.map((item, index) => (
                      <div className="pics" key={item._id}>
                        <Box
                          onClick={() => {
                            if (!deleteMultipleMode) {
                              viewGallerys(
                                item?.Images?.highResolution,
                                item?.event,
                                currentPage, // Correct the page being passed
                                item?.fileName,
                                item
                              );
                            }
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "auto",
                            }}
                          >
                            {/* Checkbox for selecting multiple images */}
                            {deleteMultipleMode && (
                              <input
                                type="checkbox"
                                checked={isImageSelected(item._id)} // Track if the image is selected
                                onChange={(event) => handleSelectImage(item._id, event)} // Prevent the gallery view from opening on checkbox click
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 10,
                                  zIndex: 10,
                                  transform: "scale(1.5)",
                                }}
                                onClick={(event) => event.stopPropagation()} // Prevent triggering the image preview when clicking the checkbox
                              />
                            )}

                            {/* Display image index */}
                            <span
                              style={{
                                position: "absolute",
                                top: 5,
                                left: 5,
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                                padding: "2px 5px",
                                borderRadius: "3px",
                                zIndex: 10, // Ensure it appears above the image
                              }}
                            >
                              {index + 1 + (currentPage - 1) * ITEMS_PER_PAGE}
                            </span>

                            {/* Display the image */}
                            <img
                              src={item?.Images?.lowResolution}
                              alt={`Image ${index + 1}`}
                              style={{
                                width: "100%",
                                // height: "200px",
                                objectFit: "cover",
                                borderRadius: 8,
                              }}
                            />
                          </div>
                        </Box>
                      </div>
                    ))}




                  </div>

                  {selectedImages.length > 0 && (
                    <Button
                      variant="contained"
                      onClick={handleDeleteSelectedImagesClick} // Use this to open the confirmation dialog
                      sx={{
                        marginTop: 2,
                        backgroundColor: "red", // Red background color
                        color: "white", // White text color
                        "&:hover": {
                          backgroundColor: "darkred", // Darker red on hover
                        },
                      }}
                    >
                      Delete Selected Images
                    </Button>
                  )}

                  <Dialog
                    open={openConfirmDelete}
                    onClose={handleCloseConfirmDelete}
                    aria-labelledby="confirm-delete-dialog"
                  >
                    <DialogTitle id="confirm-delete-dialog">Confirm Delete</DialogTitle>
                    <DialogContent>
                      <Typography>Are you sure you want to delete these selected images?</Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseConfirmDelete} color="primary">
                        Disagree
                      </Button>
                      <Button onClick={handleAgreeDelete} color="primary" autoFocus>
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Snackbar
                    open={successMessage}
                    autoHideDuration={4000} // Automatically hide after 4 seconds
                    onClose={handleCloseSuccessMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Alert onClose={handleCloseSuccessMessage} severity="success" sx={{ width: '100%' }}>
                      Images deleted successfully!
                    </Alert>
                  </Snackbar>
                  {/* Pagination */}
                  {/* {ownPhoto.length > 0 && ( */}
                  {/* <Pagination
                      count={totalPage}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                      variant="outlined"
                      shape="rounded"
                      sx={{ marginTop: 2 }}
                    /> */}
                  <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                    {/* <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  variant="outlined"
                  shape="rounded"
                /> */}
                    <Box>
                      <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                        Previous
                      </Button>
                      <span>Page {currentPage} of {totalPage}</span>
                      <Button onClick={handleNextPage} disabled={currentPage === totalPage}>
                        Next
                      </Button>
                    </Box>
                  </Box>
                  {/* )} */}
                </Box>
                {/* Loader conditionally rendered */}
                {loaderScroll && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </div>
                )}
              </>
            )}
          </TabPanel>
        )}
      </Box>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ borderRadius: 5, zIndex: 500 }}
      >
        <Box sx={{ width: "100%", ...style }}>
          <Stack
            style={{
              display: "flex",
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
              <CloseCircleTwoTone />
            </Box>
          </Stack>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {status === 401 ? (
              <SubscriptionPay
                subscriptionStatus={subscriptionStatus}
                subscriptionMessage={subscriptionMessage}
              />
            ) : null}
          </Box>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <CircularProgress color="inherit"  />  */}
          </div>

          {errorMessage ? (
            <Alert severity="error">
              <Typography>{errorMessage}</Typography>
            </Alert>
          ) : null}

          <div style={{ display: "flex", justifyContent: "center" }}></div>

          {files?.length > 0 && openModal ? (
            <>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="md"
                fullWidth
              >
                <DialogTitle>
                  <Typography variant="h6">Upload Photos</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseCircleTwoTone />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    ref={dialogContentRef}
                  >
                    <Typography variant="body1">{`${files.length} Photos Selected`}</Typography>
                    <Typography variant="body2" align="right">
                      {`${(
                        files.reduce((acc, file) => acc + file.size, 0) /
                        1024 /
                        1024
                      ).toFixed(2)} MB`}
                    </Typography>
                  </Box>
                  <BorderLinearProgress
                    variant="determinate"
                    value={totalProgress}
                    sx={{ mb: 2 }}
                  />
                  {files.map((file, index) => (
                    <Card
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        p: 1,
                        mb: 1,
                        borderRadius: 2,
                      }}
                      elevation={0}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body1" fontWeight="bold">
                            {file.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {(file.size / 1024 / 1024).toFixed(2)} MB
                          </Typography>
                        </Box>

                        <Typography
                          variant="body2"
                          sx={{
                            width: "100%",
                            mt: 1,
                            textAlign: "right",
                            color:
                              uploadProgress[file.name] === 100
                                ? "green"
                                : uploadProgress[file.name] > 0
                                  ? "orange"
                                  : "textSecondary",
                          }}
                        >
                          {uploadProgress[file.name] === 100
                            ? "UPLOADED"
                            : uploadProgress[file.name] > 0
                              ? "UPLOADING..."
                              : ""}
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%", mt: 2 }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={uploadProgress[file.name] || 0}
                          sx={{
                            height: 10,
                            borderRadius: 2,
                            backgroundColor: "#e0e0e0",
                          }}
                        />
                      </Box>

                    </Card>
                  ))}
                </DialogContent>
                <DialogActions>
                  {totalProgress > 0 ? (
                    <Button
                      onClick={() => { }}
                      variant="contained"
                      color="primary"
                      sx={{ marginLeft: 2 }}
                    >
                      Uploading..
                    </Button>
                  ) : (
                    <Button
                      onClick={handleUpload}
                      variant="contained"
                      color="primary"
                      sx={{ marginLeft: 2 }}
                    >
                      Upload Photos
                    </Button>
                  )}
                  <Button
                    onClick={() => setOpenModal(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <>
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <label
                  htmlFor="file-input-computer"
                  style={{
                    backgroundColor: "#eaf0fa",
                    color: "#1976D2",
                    padding: "10px 15px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "inline-block",
                    width: "94.5%",
                    border: "1px solid #007bff",
                  }}
                >

                  

                  {totalProgress === 100
                    ? "Upload More Photos"
                    : "Upload Your Photos"}
                </label>
                <FormHelperText>
                  Only formatted JPG and JPEG images
                </FormHelperText>
                <input
                  id="file-input-computer"
                  type="file"
                  onChange={handleFileChange}
                  multiple
                  accept=".jpg, .jpeg"
                  style={{ display: "none" }}
                />
              </div>
            </>
          )}

          {totalProgress === 100 && (
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center', justifyContent: 'space-between' }}>
              <p>
                {files.length} photos uploaded successfully: {totalProgress}%
                completed
              </p>
              <button
                onClick={handleClose}
                style={{
                  backgroundColor: "#eaf0fa",
                  color: "#1976D2",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  display: "inline-block",
                  width: isMobile ? "100%" : "auto",
                  border: "1px solid #007bff",
                }}
              >
                {"Done"}
              </button>
            </div>

          )}
        </Box>
      </Modal>
    </div>
  );
}
