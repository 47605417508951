
// import { Navigate, useRoutes } from 'react-router-dom';
// import PrivateRoute from './priveteRoute';
// import PagesRoute from './pageRoute';
// import AdminRoute from './adminRoute';
// import { useMemo, useState } from 'react';

// export default function ThemeRoutes() {
//     const [isAuthenticated, setIsAuthenticated] = useState(false);
//     const [userType, setUserType] = useState('');
//     const tk = localStorage.getItem('accessToken');

//     useMemo(() => {
//       const token = localStorage.getItem('accessToken');
//       const userType = localStorage.getItem('userType');
//       //console.log("dfdfdff", token, userType);
//       setIsAuthenticated(!!token);
//       setUserType(userType || '');
//     }, [tk]);

//     let routes = [];
//     if (isAuthenticated) {
//       if (userType === "admin") {
//         //console.log("admin")
//         // routes.push(AdminRoute,PagesRoute);
//         routes = AdminRoute;
//       } else if (userType === "user") {
//         routes = PagesRoute;
//       }
//     } else {
//       routes = PrivateRoute;
//     }

//     //console.log("routesroutes", routes);
//     return useRoutes([
//       ...routes,
//       { path: '*', element: <Navigate to="/" replace /> }
//     ]);
// }

// import { Navigate, useRoutes } from 'react-router-dom';
// import PrivateRoute from './priveteRoute';
// import { PhotographerRoutes,UserRoutes } from './pageRoute';
// import AdminRoute from './adminRoute';
// import { useMemo, useState } from 'react';

// export default function ThemeRoutes() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [userType, setUserType] = useState('');
//   const tk = localStorage.getItem('accessToken');

//   useMemo(() => {
//     const token = localStorage.getItem('accessToken');
//     const userTypeFromStorage = localStorage.getItem('userType');



//     setIsAuthenticated(!!token);
//     setUserType(userTypeFromStorage || '');
//   }, [tk]); // Assuming 'tk' is a typo and meant to be 'token'



//   let routes = [];

  
//   if (isAuthenticated) {
//     if (userType === "admin") {
//       //console.log("Admin");
//       // Concatenate AdminRoute and PagesRoute when userType is admin
//       routes = [...AdminRoute, ];
//       // routes = [...AdminRoute, ...PagesRoute];
//     } else if (userType === "user") {
//       routes = UserRoutes;
//     }else if(userType === "photographer"){
//       routes = PhotographerRoutes;
//     } else {
//       routes = PrivateRoute();
//     }
//   } else {
//     routes = PrivateRoute();
//   }

//   //console.log("Final Routes:", routes);
//   return useRoutes([
//     ...routes,
//     { path: '*', element: <Navigate to="/" replace /> }
//   ]);


// }

import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import PrivateRoute from './priveteRoute';
import { PhotographerRoutes,UserRoutes } from './pageRoute';
import AdminRoute from './adminRoute';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';

export default function ThemeRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState('');
  const tk = localStorage.getItem('accessToken');
  const navigate = useNavigate();


  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        if (error.response && error.response.status === 401 && error.response.data.error === 'Invalid access token') {
          console.log('Invalid token, removing from localStorage');
        
          localStorage.clear();
         
          setIsAuthenticated(false);
          setUserType('');

          console.log('Access token and userType removed from localStorage');
        
          navigate('/');
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        return Promise.reject(error);
      }
    );

    // Cleanup interceptor on unmount
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate, tk]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const userTypeFromStorage = localStorage.getItem('userType');

    if (token) {
      setIsAuthenticated(true);
      setUserType(userTypeFromStorage || '');
    } else {
      setIsAuthenticated(false);
      setUserType('');
    }
  }, []);


  useMemo(() => {
    const token = localStorage.getItem('accessToken');
    const userTypeFromStorage = localStorage.getItem('userType');



    setIsAuthenticated(!!token);
    setUserType(userTypeFromStorage || '');
  }, [tk]); // Assuming 'tk' is a typo and meant to be 'token'

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  let routes = [];

  
  if (isAuthenticated) {
    if (userType === "admin") {

      routes = [...AdminRoute, ];

    } else if (userType === "user") {
      routes = UserRoutes;
    }else if(userType === "photographer"){
      routes = PhotographerRoutes;
    } else {
      routes = PrivateRoute();
    }
  } else {
    routes = PrivateRoute();
  }

  //console.log("Final Routes:", routes);
  return useRoutes([
    ...routes,
    { path: '*', element: <Navigate to="/" replace /> }
  ]);


}

