// import React, { useEffect, useState } from "react";
// import {
//   Typography,
//   TableCell,
//   Backdrop,
//   CircularProgress,
//   useTheme,
//   useMediaQuery,
//   Stack,
//   Button,
//   Avatar,
//   Card,
// } from "@mui/material";
// import {
//   AccountBalanceOutlined,
//   AccountCircle,
//   DeleteOutlined,
//   EditOutlined,
//   GifBoxOutlined,
//   Group,
//   Refresh,
//   RefreshSharp,
// } from "@mui/icons-material";

// import myImage from "../../../images/nodata.png";

// import { TableCom, THead, TCell, TBody, Trow } from "../../Component/table";
// import { getEventListApi } from "../../../API/Admin";
// import Menus from "../../Component/menu";
// import { SearchField } from "../../Component/SerachField";
// import { useLocation, useNavigate } from "react-router";

// const PhotographerDetailes = () => {
//   const [photographerData, setPhotographerData] = useState([]);
//   const [metaPage, setmetaPage] = useState(1);
//   const [profile, setProfile] = useState("");
//   const theme = useTheme();
//   const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
//   const [page, setPage] = useState(1);
//   const [skeliton, setskeliton] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();
//   // const userType =localStorage.getItem("userType")
//   console.log("Location state:", location?.state);
//   //console.log("location?.state?.id",location?.state?.id)
//   const userId = location?.state?.id;
//   const getPotgrapherData = async () => {
//     const userType = "photographer";
//     setskeliton(true);
//     try {
//       const response = await getEventListApi(userId);
//       setPhotographerData(response?.data?.data);
//       //console.log("response",response)

//       setskeliton(false);
//     } catch (error) {
//       //console.log("error", error);
//     }
//   };

//   useEffect(() => {
//     getPotgrapherData();
//   }, [page]);

//   const goToPreviousPage = () => {
//     setPage(page - 1);
//   };

//   const goToNextPage = async () => {
//     try {
//       const nextPage = page + 1;
//       setPage(nextPage);
//     } catch (error) {
//       ////console.log("error", error);
//     }
//   };

//   const headers = ["Action", "Album Name", "Album Type ", "Created At"];

//   const deletePhotographer = () => {};

//   const albumCreated = () => {
//     navigate("/");
//   };
//   const menu = [
//     // { name: "Employee List", icon: <Group/>, onClick: employeeList },
//     // { name: 'Copy Referral Link', icon: <ContentCopyIcon />, onClick: handleCopylink },
//      { name: "Album created List", icon: <Group/>,  onClick:albumCreated},
//     { name: "Delete ", icon: <Group />, onClick: deletePhotographer },
//   ];

//   const searchProps = (data) => {
//     //console.log("data",data)
//   };

//   return (
//     <div style={{ marginTop: 10, marginInline: isMobileOrTablet ? 20 : 5 }}>
//       <>
//         {skeliton ? (
//           <div style={{ display: "flex", justifyContent: "center" }}>
//             <CircularProgress color="inherit" />
//           </div>
//         ) : photographerData?.length === 0 || photographerData === undefined ? (
//           <div style={{ display: "flex", justifyContent: "center" }}>
//             <img src={myImage} />
//           </div>
//         ) : (
//           <>
//             {/* <Stack width={350} >
//                 <SearchField searchProps={searchProps}/>
//             </Stack> */}

//             <div style={{ marginTop: 10 }}>
//               <TableCom>
//                 <THead>
//                   {headers?.map((header, index) => (
//                     <TableCell
//                       key={index}
//                       style={{
//                         borderRight: "1px solid white",
//                         paddingRight: "10px",
//                         fontWeight: "40%",
//                         fontSize: "100%",
//                         color: "aliceblue",
//                         backgroundColor: "#0C2142",
//                       }}
//                     >
//                       {header}
//                     </TableCell>
//                   ))}
//                 </THead>

//                 <TBody>
//                   {photographerData?.map((value, index) => (
//                     <Trow key={index}>
//                       {/* <TCell>

                           
//                         <Menus
//                           menu={menu}
//                           userdata={value}
//                           types={"skill"}
//                           setProfile={setProfile}
//                         />
//                         </TCell> */}

//                       <TCell>
//                         <Typography>
//                           {value.name ? value.name : "N/A"}
//                         </Typography>
//                       </TCell>

//                       <TCell>
//                         <Typography>
//                           {value.number ? value.number : "N/A"}
//                         </Typography>
//                       </TCell>

//                       <TCell>
//                         <Typography>
//                           {value.date ? value.date.split("T")[0] : "N/A"}
//                         </Typography>
//                       </TCell>
//                     </Trow>
//                   ))}
//                 </TBody>
//               </TableCom>
//             </div>

//             <Stack
//               direction={"row"}
//               style={{ justifyContent: "flex-end", marginTop: 10 }}
//               spacing={1}
//             >
//               <Typography style={{ marginTop: 8 }}>
//                 TotalPage:<span fontWeight={"bold"}> {metaPage}</span>
//               </Typography>
//               <Button
//                 onClick={goToPreviousPage}
//                 disabled={page === 1}
//                 variant="outlined"
//               >
//                 Pre..
//               </Button>
//               <Typography fontWeight={"bold"} style={{ marginTop: 8 }}>
//                 {page}
//               </Typography>
//               <Button
//                 onClick={goToNextPage}
//                 disabled={page === metaPage}
//                 variant="outlined"
//               >
//                 Next
//               </Button>
//             </Stack>
//           </>
//         )}
//       </>
//     </div>
//   );
// };

// export default PhotographerDetailes;




import React, { useEffect, useState } from "react";
import {
  Typography,
  TableCell,
  Backdrop,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Stack,
  Modal,
  Box,
  Button,
  Card,
  Avatar
} from "@mui/material";
import { Group } from "@mui/icons-material";
import myImage from "../../../images/nodata.png";
import { SearchField } from "../../Component/SerachField";
import {
  TableCom,
  THead,
  TCell,
  TBody,
  Trow
} from "../../Component/table";
import { DeletePhotographerApi, getphotographerList } from "../../../API/Admin";
import Menus from "../../Component/menu";
import { useLocation, useNavigate } from "react-router";

const PhotographerDetailes = () => {
  const [photographerData, setPhotographerData] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [metaPage, setmetaPage] = useState(1);
  const [profile, setProfile] = useState('');
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);
  const [skeliton, setskeliton] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [photographerId, setphotographerId] = useState('');
  

  const location = useLocation();
  const navigate = useNavigate();
  const photographerEmail = location?.state?.name;
  console.log("dghfffffh",photographerEmail)

  const getPotgrapherData = async () => {
    const userType = "user";
    const data = {
      userType,
      searchData:photographerEmail,
      page:1
    };
    setskeliton(true);
    try {
      const response = await getphotographerList(data);
      console.log("gggg",response?.data?.data[0]?.events);

 

      setPhotographerData(response?.data?.data[0]?.events);
      setmetaPage(response?.data?.pagination?.totalPages);
      //console.log("response", response);
      setskeliton(false);
    } catch (error) {
      //console.log("error", error);
    }
  };

  useEffect(() => {
    getPotgrapherData();
  }, [photographerEmail, page]);

  const useDebounce = (inputValue, delay) => {
    const [debounceValue, setDebounceValue] = useState(inputValue);
    useEffect(() => {
      const storvalue = setTimeout(() => {
        setDebounceValue(inputValue);
      }, delay);
      return () => {
        clearTimeout(storvalue);
      };
    }, [inputValue, delay]);
    return debounceValue;
  };

  const finalSearchValue = useDebounce(searchData, 1500);

  useEffect(() => {
    getPotgrapherData();
  }, [page, finalSearchValue]);

  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  const goToNextPage = async () => {
    try {
      const nextPage = page + 1;
      setPage(nextPage);
    } catch (error) {
      // //console.log("error", error);
    }
  };

  const headers = [
    "Album Name",
    "Album Type",
    "No of Photos Uploaded",
    "Album Created Date",
  
  ];

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };


  const calculateTotalCredits = (subscriptionHistory) => {
    let total = 0;
    subscriptionHistory?.forEach((item) => {
      total += item.creadits;
    });
    return total;
  };

  return (
    
    <div style={{ marginTop: 10, marginInline: isMobileOrTablet ? 20 : 5 }}>
      
      <>
        {skeliton ? (
          <div style={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </div>
        ) : photographerData?.length === 0 || photographerData === undefined ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={myImage} />
          </div>
        ) : (
          <>
            {/* <Stack width={350}>
              <SearchField searchProps={searchProps} placeholder="Search Name,Email" />
            </Stack> */}

            <div style={{ marginTop: 10 }}>
             

              <TableCom>
                <THead>
                  {headers?.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        borderRight: "1px solid white",
                        paddingRight: "10px",
                        fontWeight: "40%",
                        fontSize: "100%",
                        color: "aliceblue",
                        backgroundColor: "#0C2142"
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </THead>

                <TBody>
                  {photographerData?.map((value, index) => (
                    <Trow key={index} >
                      
                      <TCell>
                        <Typography>{value.albumName ? value.albumName : "N/A"}</Typography>
                      </TCell>
                      <TCell>
                        <Typography>{value.eventType? value.eventType: "N/A"}</Typography>
                      </TCell>
                      <TCell>
                        <Typography>{value.totalUploadImages ? value.totalUploadImages: "N/A"}</Typography>
                      </TCell>
                      <TCell>
                        <Typography>
                          {value.createdAt
                            ? new Date(value.createdAt).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                              })
                            : "N/A"}
                        </Typography>
                      </TCell>
                   
                    </Trow>
                  ))}
                </TBody>
              </TableCom>
            </div>

            <Stack direction={"row"} style={{ justifyContent: "flex-end", marginTop: 10 }} spacing={1}>
              <Typography style={{ marginTop: 8 }}>TotalPage:<span fontWeight={"bold"}> {metaPage}</span></Typography>
              <Button onClick={goToPreviousPage} disabled={page === 1} variant="outlined">Pre..</Button>
              <Typography fontWeight={"bold"} style={{ marginTop: 8 }}>{page}</Typography>
              <Button onClick={goToNextPage} disabled={page === metaPage} variant="outlined">Next</Button>
            </Stack>
          </>
        )}
      </>
    </div>
  );
};

export default PhotographerDetailes;

